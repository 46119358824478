<template>
  <div class="calendarCellMultiple" v-bind="$attrs" @click="$emit('click')">
    <div :class="['calendarCellMultiple__date', { 'm-active': isActive }]">
      {{ date }}
    </div>
    <div v-if="day.isNonWorking" class="calendarCellMultiple__nonWorking">
      BLACKOUT DATE
    </div>
    <template v-else>
      <div class="calendarCellMultiple__slots">
        <div
          class="calendarCellMultiple__slot"
          v-for="(slot, index) in day.slots"
          :key="index"
        >
          <span :class="['m-overflowed', { 'm-bold': isUpcoming }]">{{
            getTime(slot)
          }}</span>
          <span class="calendarCellMultiple__completeness">
            {{ slot.taken }}/{{ slot.capacity }}
          </span>
        </div>
      </div>
      <div v-if="extraText" class="calendarCellMultiple__more m-overflowed">
        {{ extraText }}
      </div>
    </template>
  </div>
</template>
<script>
import {
  getMinutes,
  getSlotTimeFromMinutes,
  getHourName,
  getTimezoneOffsetHours,
} from "@/helpers/utils";
import moment from "moment";
import { checkActive } from "@/helpers/utils";
import { mapState } from "vuex";

export default {
  name: "CalendarCellMultiple",
  props: {
    day: {
      type: Object,
      required: true,
    },
    selectedDay: {
      type: [String, Boolean],
      default: false,
    },
  },
  data: () => ({
    checkActive,
  }),
  computed: {
    ...mapState({
      venue: (state) => state.venues.selectedVenue,
    }),
    isActive() {
      return checkActive(this.day.date, this.selectedDay);
    },
    isUpcoming() {
      return moment().startOf("date").isSameOrBefore(moment(this.day.date));
    },
    extra() {
      return this.day.total - 3;
    },
    isExtra() {
      return this.extra > 0;
    },
    date() {
      return moment(this.day.date).date();
    },
    extraText() {
      if (this.isExtra) return `${this.extra} more availabilities...`;
      if (!this.day.slots.length) return "No availabilities yet...";

      return "";
    },
  },
  methods: {
    getFromWithOffset(slot) {
      const utcOffset = getTimezoneOffsetHours(this.venue.timezone);
      return getSlotTimeFromMinutes(getMinutes(slot.from) + utcOffset * 60);
    },
    getToWithOffset(slot) {
      const utcOffset = getTimezoneOffsetHours(this.venue.timezone);
      return getSlotTimeFromMinutes(getMinutes(slot.to) + utcOffset * 60);
    },
    getTime(slot) {
      return `${getHourName(
        this.getFromWithOffset(slot).hours,
        this.getFromWithOffset(slot).minutes
      ).toUpperCase()} - ${getHourName(
        this.getToWithOffset(slot).hours,
        this.getToWithOffset(slot).minutes
      ).toUpperCase()}`;
    },
  },
};
</script>

<style lang="scss">
.calendarCellMultiple {
  height: 128px;
  padding: 8px 10px 10px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid $secondary-600;
  cursor: pointer;
  width: calc(100% / 7);

  &:last-child {
    border-color: $secondary-300;
  }

  &__slots {
    width: 100%;
  }

  &__date {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    opacity: 0.5;
    color: $secondary-500;
    margin: 0 auto 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.m-active {
      background-color: $primary;
      color: $white;
      opacity: 1;
    }
  }

  &__slot {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: $secondary-500;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &.m-bold {
      font-weight: 700;
    }
  }

  &__completeness {
    opacity: 0.5;
  }

  &__more {
    margin-top: auto;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: $secondary-500;
    opacity: 0.5;
  }

  .m-overflowed {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 4px;
  }

  .m-bold {
    font-weight: 700;
  }

  &__nonWorking {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    opacity: 0.5;
    text-transform: uppercase;
    color: $secondary-600;

    height: 77px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
