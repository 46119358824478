<template>
  <div class="addSlotsCountInput">
    <input v-model="innerValue" readonly />
    <div class="addSlotsCountInput__controls">
      <Icon
        name="chevron-down"
        isClickable
        class="addSlotsCountInput__controls__up"
        color="secondary-400"
        :size="14"
        @click="innerValue++"
      />
      <Icon
        name="chevron-down"
        isClickable
        class="addSlotsCountInput__controls__down"
        color="secondary-400"
        :size="14"
        @click="innerValue--"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "NumericCounterInput",
  props: {
    value: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
    },
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", Math.min(Math.max(this.min, newVal), this.max));
      },
    },
  },
};
</script>

<style scoped lang="scss">
.addSlotsCountInput {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  padding: 0 2px 0 8px;
  border-radius: 8px;
  border: 1px solid $secondary-300;
  background: $white;

  > input {
    border: none;
    color: $secondary-500;
    font-size: 12px;
    font-weight: 400;
    line-height: 28px;
    max-width: 20px;
  }

  &__controls {
    display: flex;
    flex-direction: column;
    &__up {
      transform: rotate(180deg);
    }
  }
}
</style>
