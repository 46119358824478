var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calendarView"},[_c('div',{staticClass:"calendarView__lines"},_vm._l((24),function(line){return _c('div',{key:line,staticClass:"calendarView__line"})}),0),_vm._l((_vm.sortedSchedule),function(day,index){return _c('div',{key:`day-${index}`,class:[
      'calendarView__day',
      {
        'm-active': day && _vm.checkActive(day.date, _vm.selectedDay),
      },
    ]},[(!_vm.isLoading)?[(!day.isNonWorking)?[_vm._l((_vm.groupDuplicates(day.slots)),function(slots,key){return _c('ContextMenu',{key:`slot-${key}`,attrs:{"options":_vm.getContextMenu(
              slots[
                _vm.duplicateGroupsPrimarySlotIndexMap[`${day.date}-${key}`] || 0
              ]
            )},on:{"select":function($event){return _vm.$emit(
              'menu-item-select',
              $event,
              slots[
                _vm.duplicateGroupsPrimarySlotIndexMap[`${day.date}-${key}`] || 0
              ]
            )}}},[_c('calendar-cell-single',{staticClass:"calendarView__cell",attrs:{"slots":slots,"height-coef":_vm.heightCoef,"primary-slot-index":_vm.duplicateGroupsPrimarySlotIndexMap[`${day.date}-${key}`]},on:{"update:primarySlotIndex":function($event){return _vm.$set(_vm.duplicateGroupsPrimarySlotIndexMap, `${day.date}-${key}`, $event)},"update:primary-slot-index":function($event){return _vm.$set(_vm.duplicateGroupsPrimarySlotIndexMap, `${day.date}-${key}`, $event)}}})],1)}),_vm._l((_vm.getPreviewSlots(day.date)),function(slot){return _c('calendar-cell-single',{key:slot.uuid,staticClass:"calendarView__cell",attrs:{"slots":[slot],"height-coef":_vm.heightCoef,"is-preview":""}})})]:_vm._l((24),function(i){return _c('div',{key:i,staticClass:"calendarView__nonWorking"},[_vm._v(" "+_vm._s(i % 2 ? _vm.$t("Blackout date") : "")+" ")])})]:_vm._e()],2)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }