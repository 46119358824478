<template>
  <CustomModal
    :name="modalName"
    width="350px"
    :classes="['calendarNonWorkingDaysModal']"
    @before-close="reset"
  >
    <div class="calendarNonWorkingDaysModal__close">
      <Icon
        name="close"
        is-clickable
        color="secondary-500"
        :size="16"
        @click="$modal.hide(modalName)"
      />
    </div>
    <template v-slot:header>
      <span class="calendarNonWorkingDaysModal__title">
        {{ title }}
      </span>
    </template>
    <div class="calendarNonWorkingDaysModal__calendar">
      <calendar-view
        ref="calendar"
        :date="mappedDays"
        :firstDayOfWeek="6"
        :is-dates-before-today-blocked="true"
        is-multiple
        variant="tertiary"
        @selectDate="handleDateSelect"
      />
    </div>
    <Button
      is-block
      :isDisabled="!isChanged"
      :is-loading="isSubmitting"
      @click="$emit('input', selectedDays)"
    >
      {{ buttonText }}
    </Button>
  </CustomModal>
</template>

<script>
import { Components } from "@zteam/booking-axe-sb";
import moment from "moment";
import { getNumberWithZero } from "@/helpers/utils";

export default {
  name: "CalendarSelectDaysModal",
  components: {
    CalendarView: Components.CalendarView,
  },
  props: {
    modalName: {
      type: String,
      default: "calendarNonWorkingDaysModal",
    },
    value: {
      type: Array,
      default: () => [],
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Select days",
    },
  },
  data() {
    return {
      selectedDays: [],
    };
  },
  watch: {
    value() {
      this.reset();
    },
  },
  created() {
    this.reset();
  },
  computed: {
    mappedDays() {
      return this.selectedDays.map((day) => {
        const date = moment(day, "YYYY-MM-DD");

        return {
          date: date.date(),
          month: date.month(),
          year: date.year(),
        };
      });
    },
    buttonText() {
      return this.selectedDays.length
        ? `Save (${this.selectedDays.length} day${
            this.selectedDays.length > 1 ? "s" : ""
          })`
        : "Save";
    },
    isChanged() {
      return (
        JSON.stringify([...this.value].sort((a, b) => a.localeCompare(b))) !==
        JSON.stringify(
          [...this.selectedDays].sort((a, b) => a.localeCompare(b))
        )
      );
    },
  },
  methods: {
    handleDateSelect(days) {
      this.selectedDays = days.map(
        (day) =>
          `${day.year}-${getNumberWithZero(day.month + 1)}-${getNumberWithZero(
            day.date
          )}`
      );
    },
    reset() {
      this.selectedDays = this.value.filter((day) => {
        const date = moment(day, "YYYY-MM-DD");
        return date.isSameOrAfter();
      });
    },
  },
};
</script>

<style lang="scss">
.vm--container {
  .calendarNonWorkingDaysModal.vm--modal {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 40px 24px 24px;
    max-width: 350px;
    width: auto;
  }
}
</style>

<style lang="scss" scoped>
.calendarNonWorkingDaysModal {
  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: $secondary-500;
    margin-bottom: 32px;
  }

  &__calendar {
    margin-bottom: 16px;
  }

  .date-picker {
    padding: 0;
  }
}
</style>
