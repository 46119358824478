<template>
  <aside v-show="isOpen" class="calendar-drawer">
    <div class="calendar-drawer__header mb-24">
      <div class="calendar-drawer__header">
        <Icon
          v-if="isCreating || isEditing"
          isClickable
          name="arrow-left"
          @click="cancel"
        />
        <h2 class="calendar-drawer__title">
          {{
            isCreating
              ? "Add availability"
              : isEditing
              ? "Edit availability"
              : "Availability"
          }}
        </h2>
      </div>
      <div class="calendar-drawer__header">
        <Icon
          v-if="isEditing && !isSubmitting"
          name="trash"
          color="danger"
          isClickable
          @click="deleteGroup"
        />
        <Icon
          name="close"
          color="secondary-500"
          isClickable
          @click="$emit('close')"
        />
      </div>
    </div>
    <AvailabilityForm
      v-if="isCreating || isEditing"
      :is-submitting="isSubmitting"
      @save="handleSubmit"
    />
    <template v-else>
      <Guard permission="calendar.create" v-slot="{ isAvailable }">
        <IconButton
          v-if="isAvailable"
          icon="plus"
          label-position="left"
          class="mb-24"
          @click="addGroup"
        >
          Add availability
        </IconButton>
      </Guard>
      <Loader v-if="isLoading" color="primary" size="m" class="mx-auto" />
      <template v-else-if="groups?.length">
        <AvailabilityCard
          v-for="group in groups"
          :key="group.id"
          :group="formatGroup(group)"
          class="mb-24"
          @mouseover.native="handleMouseOver(group)"
          @mouseleave.native="handleMouseLeave"
          @click.native="editGroup(group)"
        />
      </template>
      <p v-else class="empty">You haven't created availabilities yet</p>
    </template>
  </aside>
</template>

<script>
import AvailabilityCard from "@/components/calendar/availabilities/AvailabilityCard.vue";
import { mapState } from "vuex";
import moment from "moment/moment";
import stringToColor from "string-to-color";
import AvailabilityForm from "@/components/calendar/availabilities/AvailabilityForm.vue";
import Guard from "@/components/common/Guard.vue";

export default {
  name: "CalendarDrawer",
  components: { Guard, AvailabilityForm, AvailabilityCard },
  props: {
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      groups: (state) => state.calendar.groups,
      editingGroup: (state) => state.calendar.editingGroup,
      isOpen: (state) => state.calendar.isDrawerOpen,
    }),
    isCreating() {
      return !!this.editingGroup && !this.editingGroup.id;
    },
    isEditing() {
      return !!this.editingGroup?.id;
    },
  },
  watch: {
    isOpen(newVal) {
      if (!newVal) {
        this.cancel();
      }
    },
  },
  methods: {
    formatGroup(group) {
      const startDate = moment(group.startDate).format("DD MMM, YYYY");
      const endDate =
        group.endRepeat &&
        moment(group.startDate)
          .add(group.endRepeat, "months")
          .format("DD MMM, YYYY");

      return {
        ...group,
        ...(startDate ? { startDate } : {}),
        ...(endDate ? { endDate } : {}),
        color: stringToColor(group.id),
      };
    },
    handleMouseOver(group) {
      this.$store.commit("calendar/SET_HIGHLIGHTED_GROUP_ID", group.id);
    },
    handleMouseLeave() {
      this.$store.commit("calendar/SET_HIGHLIGHTED_GROUP_ID", null);
    },
    editGroup(group) {
      this.$store.commit("calendar/SET_EDITING_GROUP", group);
    },
    addGroup() {
      this.$emit("start-create");
      this.$store.commit("calendar/SET_EDITING_GROUP", {});
    },
    deleteGroup() {
      if (this.editingGroup) {
        this.$emit("delete", this.editingGroup);
      }
    },
    cancel() {
      this.$store.commit("calendar/SET_EDITING_GROUP", null);
    },
    handleSubmit(form) {
      if (!form.id) {
        this.$emit("create", form);
      } else {
        this.$emit("update", form);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.calendar-drawer {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background: $white;
  box-shadow: 40px 28px 80px 0 rgba(16, 11, 85, 0.08),
    -8px -4px 32px 0px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  padding: 24px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  @media (min-width: $media-laptop) {
    width: 380px;
    position: static;
    height: 100%;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    color: $secondary-500;
    font-size: 18px;
    font-weight: 500;
  }
}
</style>
