<template>
  <div class="time-input" :class="{ 'm-nano': isNano }">
    <p v-if="label" class="time-input__label">{{ label }}</p>
    <div class="time-input__control">
      <FormItem rules="required|time" class="w-100" v-slot="{ isError }">
        <Input
          v-model="innerValue"
          :max="5"
          :is-floating="false"
          :placeholder="placeholder"
          :is-small="isSmall"
          :is-mini="isMini"
          :is-disabled="isDisabled"
          :is-error="isError"
        />
      </FormItem>
      <div
        v-if="hasTimeButtons && hoursFormat === 12"
        class="time-input__time"
        :class="{
          'm-disabled': isDisabled,
        }"
      >
        <div
          :class="[
            'time-input__time-button',
            {
              'm-active': innerActiveTime === 'am',
              'm-small': isSmall,
              'm-mini': isMini,
              'm-nano': isNano,
            },
          ]"
          @click="updateActiveTime('am')"
        >
          AM
        </div>
        <div
          :class="[
            'time-input__time-button',
            {
              'm-active': innerActiveTime === 'pm',
              'm-small': isSmall,
              'm-mini': isMini,
              'm-nano': isNano,
            },
          ]"
          @click="updateActiveTime('pm')"
        >
          PM
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getVenueTimeFormatHours } from "@/helpers/utils";

export default {
  name: "TimeInput",
  props: {
    value: {
      type: String,
      default: "",
    },
    activeTime: {
      type: String,
      default: "am",
      validator: (val) => ["am", "pm"].includes(val),
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    hasTimeButtons: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    isMini: {
      type: Boolean,
      default: false,
    },
    isNano: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hoursFormat() {
      return getVenueTimeFormatHours();
    },
    innerValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        let [hour, minute] = newValue.replace(/[^\d+:]/g, "").split(":");

        const getValue = () => {
          if (!minute) {
            if (hour.length === 2) {
              if (this.hasTimeButtons && +hour > this.hoursFormat) {
                hour = this.hoursFormat.toString();
              }

              return `${hour}:`;
            }
            return hour;
          } else {
            if (minute.length === 2) {
              if (+minute > 59) {
                minute = "59";
              }
              if (hour.length === 1) hour = `0${hour}`;
            }
            return [hour, minute].join(":");
          }
        };

        this.$emit("input", getValue());
      },
    },
    innerActiveTime: {
      get() {
        return this.activeTime;
      },
      set(newValue) {
        this.$emit("update:activeTime", newValue);
      },
    },
  },
  methods: {
    timeMask(value) {
      let hours = [/[0-2]/, value.charAt(0) === "2" ? /[0-3]/ : /[0-9]/];
      if (this.hoursFormat === 12) {
        hours = [/[0-1]/, value.charAt(0) === "1" ? /[0-2]/ : /[0-9]/];
      }
      const minutes = [/[0-5]/, /[0-9]/];
      return value.length > 2 ? [...hours, ":", ...minutes] : hours;
    },
    updateActiveTime(time) {
      if (!this.isDisabled) {
        this.innerActiveTime = time;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.time-input {
  &__control {
    display: flex;
    align-items: stretch;
    border: 2px solid $secondary-400;
    border-radius: 8px;
    background: $white;
  }

  ::v-deep {
    input {
      min-width: 42px;
      border: none;
    }
  }

  &.m-nano {
    ::v-deep {
      input {
        padding: 0 4px;
        text-align: center;
        font-size: 12px;
        line-height: 28px;
      }
    }
  }

  &__label {
    color: $secondary-500;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    margin-bottom: 10px;
  }

  &__time {
    display: flex;
    flex-direction: column;
    border-left: 2px solid $secondary-400;
    z-index: 1;

    &.m-disabled {
      opacity: 0.4;
      cursor: not-allowed;

      > * {
        pointer-events: none;
      }
    }
  }

  &__time-button {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: $secondary-400;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 3px;
    flex: 1;
    background: $white;

    &:first-child {
      border-top-right-radius: 6px;
    }
    &:last-child {
      border-bottom-right-radius: 6px;
    }

    &.m-small {
      line-height: 12px;
    }
    &.m-mini {
      line-height: 8px;
    }
    &.m-nano {
      font-size: 10px;
      line-height: 8px;
    }

    &.m-active {
      background: $secondary-300;
      color: $secondary-500;
    }
  }
}
</style>
