<template>
  <div class="calendarTimes">
    <div
      class="calendarTimes__cell"
      v-for="(cell, index) in cells"
      :key="index"
    >
      {{ cell.toUpperCase() }}
    </div>
  </div>
</template>
<script>
import { getHourName } from "@/helpers/utils";

export default {
  name: "CalendarTimes",
  computed: {
    cells() {
      return Array.from({ length: 24 }, (_, i) => getHourName(i + 1));
    },
  },
};
</script>
<style lang="scss">
.calendarTimes {
  width: 24px;
  padding-right: 2px;
  display: flex;
  flex-direction: column;
  flex: none;
  position: absolute;
  top: 0;
  left: 0;

  @media (max-width: $media-laptop - 1) {
    position: relative;
  }

  &__cell {
    font-weight: 300;
    font-size: 8px;
    line-height: 8px;
    color: #b4b9c4;
    height: 77px;
    text-align: right;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}
</style>
