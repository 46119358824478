<template>
  <div
    :class="['calendarViewMonth', { 'm-mobile': isMobile }]"
    :is="isMobile ? 'table' : 'div'"
  >
    <div class="calendarViewMonth__wrapper" :is="isMobile ? 'tbody' : 'div'">
      <div
        class="calendarViewMonth__row"
        :is="isMobile ? 'tr' : 'div'"
        v-for="(week, weekIndex) in weeklySchedule"
        :key="weekIndex"
      >
        <template v-for="index in weekdays">
          <calendar-cell-multiple
            v-if="week[index] && !isMobile"
            :day="week[index]"
            :key="`${weekIndex}-${index}`"
            :selected-day="selectedDay"
            @click="$emit('click', week[index])"
          />
          <td
            :class="[
              'calendarViewMonth__mobileCell',
              {
                'm-border-top-left': index === 1 && weekIndex === 0,
                'm-border-top-right': index === 7 && weekIndex === 0,
                'm-border-bottom-right':
                  (!weeklySchedule[weekIndex + 1] && !week[index]) ||
                  (weeklySchedule[weekIndex + 1] &&
                    !weeklySchedule[weekIndex + 1][index] &&
                    !week[index]),
                'm-border-bottom-left':
                  index === 1 &&
                  (!weeklySchedule[weekIndex + 1] ||
                    !weeklySchedule[weekIndex + 1][index]),
              },
            ]"
            v-else-if="week[index]"
            :key="`${weekIndex}-${index}`"
            @click="$emit('click', week[index])"
          >
            <div
              :class="[
                'calendarViewMonth__mobileCellWrapper',
                { 'm-active': checkActive(week[index].date, selectedDay) },
                { 'm-disabled': week[index].isNonWorking },
              ]"
            >
              {{ moment(week[index].date).date() }}
            </div>
          </td>
          <td
            v-else
            :key="`${weekIndex}-${index}`"
            class="calendarViewMonth__emptyCell"
          />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import CalendarCellMultiple from "../calendarCell/CalendarCellMultiple";
import { checkActive } from "@/helpers/utils";
import { mapState } from "vuex";

export default {
  name: "CalendarViewMonth",
  components: { CalendarCellMultiple },
  data: () => ({
    moment,
    checkActive,
  }),
  props: {
    schedule: {
      type: Array,
      required: true,
    },
    selectedDay: {
      type: [String, Boolean],
      default: false,
    },
  },
  computed: {
    ...mapState({
      selectedVenue: (state) => state.venues.selectedVenue,
    }),
    isMobile() {
      return ["xs", "sm"].includes(this.$mq);
    },
    sortedSchedule() {
      return [...this.schedule].sort(
        (a, b) => +new Date(a.date) - +new Date(b.date)
      );
    },
    weeklySchedule() {
      const schedule = [{}];
      let weekIndex = 0;
      const lastDayOfWeek = ((this.selectedVenue?.firstDayOfWeek || 0) + 6) % 7;

      for (
        let dayIndex = 0;
        dayIndex < this.sortedSchedule.length;
        dayIndex++
      ) {
        const day = this.sortedSchedule[dayIndex];

        if (!schedule[weekIndex]) {
          schedule.push({});
        }

        const week = schedule[weekIndex];
        const dayOfWeek = moment(day.date).day();

        if (!week[dayOfWeek]) {
          week[dayOfWeek] = day;
        } else {
          weekIndex++;
        }

        if (week[lastDayOfWeek]) {
          weekIndex++;
        }
      }

      return schedule;
    },
    weekdays() {
      return new Array(7)
        .fill(0)
        .map(
          (_, index) => (index + (this.selectedVenue?.firstDayOfWeek || 0)) % 7
        );
    },
  },
};
</script>
<style lang="scss">
.calendarViewMonth {
  width: 100%;
  border-collapse: collapse;

  &__wrapper {
    width: 100%;
    @media (min-width: $media-laptop) {
      display: flex;
      flex-direction: column;
    }
  }

  &__row {
    border-bottom: 1px solid $secondary-600;
    &:last-child {
      border-color: $secondary-300;
    }

    @media (min-width: $media-laptop) {
      display: flex;
      width: 100%;
    }
  }

  &__emptyCell {
    @media (min-width: $media-laptop) {
      height: 128px;
      border-right: 1px solid $secondary-600;
      width: calc(100% / 7);

      &:last-child {
        border-color: $secondary-300;
      }
    }
  }

  &__mobileCell {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: $secondary-500;
    border: 2px solid #e8ecf4;
    cursor: pointer;

    &.m-border-top-left {
      border-top-left-radius: 10px;
    }
    &.m-border-top-right {
      border-top-right-radius: 10px;
    }
    &.m-border-bottom-right {
      border-bottom-right-radius: 10px;
    }
    &.m-border-bottom-left {
      border-bottom-left-radius: 10px;
    }
  }

  &__mobileCellWrapper {
    border-radius: 8px;
    padding: 10px;

    &.m-disabled:not(.m-active) {
      opacity: 0.2;
    }
    &.m-active {
      background-color: $primary;
      color: $white;
    }
  }
}
</style>
