<template>
  <div class="calendarControl">
    <div
      v-if="
        ['xs', 'sm'].includes($mq) && viewType === CALENDAR_VIEW_TYPE_ENUM.WEEK
      "
      class="calendarControl__back"
      @click="handleViewTypeClick(CALENDAR_VIEW_TYPE_ENUM.MONTH)"
    >
      <Icon name="arrow-back" color="secondary-500" />
      <Title class="title" :level="2" color="secondary-500">
        {{ backMonth }}
      </Title>
    </div>
    <div class="calendarControl__row">
      <CalendarProductSelector v-model="productIds" :products="products" />
    </div>
    <div class="calendarControl__row m-reverse">
      <div class="calendarControl__monthWrapper">
        <span class="calendarControl__month">
          {{ month }}
        </span>
        <div class="calendarControl__monthControls">
          <icon-button
            icon="arrow-back"
            icon-color="secondary-500"
            is-raised
            @click="$emit('change-month', 'prev')"
          />
          <icon-button
            class="calendarControl__buttonNext"
            icon="arrow-back"
            icon-color="secondary-500"
            is-raised
            @click="$emit('change-month', 'next')"
          />
        </div>
      </div>
      <div v-if="!['xs', 'sm'].includes($mq)" class="calendarControl__toggle">
        <div
          v-for="type in viewTypes"
          :key="type.value"
          :class="[
            'calendarControl__toggleButton',
            { 'm-active': viewType === type.value },
          ]"
          @click="handleViewTypeClick(type.value)"
        >
          {{ $t(type.label) }}
        </div>
      </div>
      <div class="calendarControl__buttonWrapper">
        <Button
          class="calendarControl__button so specific selector"
          variant="secondary"
          is-small
          is-outlined
          @click="handleTodayClick"
        >
          {{ $t("Today") }}
        </Button>
        <Guard permission="calendar.setNonWorkingDays" v-slot="{ isAvailable }">
          <Button
            v-if="isAvailable && singleProduct"
            class="calendarControl__button so specific selector"
            variant="secondary"
            is-small
            is-outlined
            @click="$emit('non-working-days-click', 'next')"
          >
            {{ $t("Blackout dates") }}
          </Button>
        </Guard>
        <Button
          class="calendarControl__button so specific selector"
          variant="secondary"
          is-small
          is-outlined
          @click="$emit('toggleAvailabilityDrawer')"
        >
          {{ $t("Availability") }}
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
// import Dropdown from "@/components/common/Dropdown";
import { mapActions, mapMutations, mapState } from "vuex";
import { CALENDAR_VIEW_TYPE_ENUM } from "@/helpers/mocks";
import { getDate } from "@/helpers/utils";
import Guard from "@/components/common/Guard";
import CalendarProductSelector from "@/components/calendar/CalendarProductSelector.vue";

export default {
  name: "CalendarControl",
  components: { CalendarProductSelector, Guard /*Dropdown*/ },
  props: {
    backMonth: {
      type: String,
      default: "",
    },
    month: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    CALENDAR_VIEW_TYPE_ENUM,
    viewTypes: [
      {
        label: "W",
        value: CALENDAR_VIEW_TYPE_ENUM.WEEK,
      },
      {
        label: "M",
        value: CALENDAR_VIEW_TYPE_ENUM.MONTH,
      },
    ],
  }),
  computed: {
    ...mapState({
      products: (state) => state.products.list,
      selectedProductId: (state) => state.products.selectedProduct?.id,
      viewType: (state) => state.calendar.view,
    }),
    productIds: {
      get() {
        return this.$store.state.calendar.productIds;
      },
      set(newVal) {
        this.$store.commit("calendar/SET_PRODUCT_IDS", newVal);
      },
    },
    singleProduct() {
      return this.productIds.length === 1
        ? this.products.find((item) => item.id === this.productIds[0])
        : null;
    },
  },
  async created() {
    await this.fetchProducts();
  },
  methods: {
    ...mapActions({
      fetchProducts: "products/fetchProducts",
    }),
    ...mapMutations({
      SET_SELECTED_PRODUCT: "products/SET_SELECTED_PRODUCT",
      SET_DATE: "calendar/SET_DATE",
      SET_VIEW: "calendar/SET_VIEW",
    }),
    handleProductSelect({ value }) {
      this.SET_SELECTED_PRODUCT(
        this.products.find((item) => item.id === value)
      );
    },
    handleTodayClick() {
      this.SET_DATE(getDate(undefined, false).format("YYYY-MM-DD"));
      this.SET_VIEW(CALENDAR_VIEW_TYPE_ENUM.WEEK);
    },
    handleViewTypeClick(type) {
      this.SET_VIEW(type);
    },
  },
};
</script>
<style lang="scss">
.calendarControl {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 24px;

  &__back {
    display: flex;
    gap: 8px;
    margin-bottom: 14px;
    cursor: pointer;
  }

  &__row {
    display: flex;
    align-items: center;
    gap: 25px;
    flex-wrap: wrap;

    &.m-reverse {
      @media (max-width: $media-laptop - 1) {
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 24px;
      }
    }

    @media (max-width: $media-laptop - 1) {
      gap: 5px;
    }
  }

  &__monthControls {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__buttonNext {
    transform: rotate(180deg);
  }

  &__buttonWrapper {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__month {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.025em;
    color: $secondary-500;
  }

  &__monthWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__button.so.specific.selector {
    width: auto;
    padding: 9px 18px;
    border-radius: 8px;
    min-width: 100px;
    background: $white;

    @media (max-width: $media-laptop - 1) {
      padding: 8px;
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__toggle {
    display: flex;
    border: 1px solid $secondary-300;
    border-radius: 8px;
    overflow-x: hidden;
  }

  &__toggleButton {
    background-color: $white;
    min-width: 60px;
    padding: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $secondary-500;
    cursor: pointer;
    transition: 0.3s;
    border-right: 1px solid $secondary-300;
    border-left: 1px solid $secondary-300;
    display: flex;
    align-items: center;
    justify-content: center;

    &.m-active {
      background-color: $secondary-300;
    }
    &:first-child {
      border-left: none;
    }
    &:last-child {
      border-right: none;
    }
  }
}
</style>
