<template>
  <Form class="availabilityForm" data-v-step="28" v-slot="{ invalid }">
    <div class="availabilityForm__tabs">
      <span :class="{ 'm-disabled': activeStep !== 'dates' }">Dates</span>
      <span>—</span>
      <span :class="{ 'm-disabled': activeStep !== 'slots' }">Time slots</span>
    </div>
    <template v-if="activeStep === 'dates'">
      <FormItem rules="required">
        <Select
          :value="form.productId"
          :options="productOptions"
          label="Product"
          placeholder="Product"
          :isDisabled="!!form.id"
          @input="handleChange('productId', $event)"
        />
      </FormItem>
      <FormItem>
        <Input
          :value="form.label"
          label="Group label"
          placeholder="Group label"
          isTextInput
          @input="handleChange('label', $event)"
        />
      </FormItem>
      <Switcher
        label="Repeat scheduling"
        v-model="isRepeat"
        :isDisabled="!!form.id"
      />
      <div :class="isRepeat ? 'availabilityForm__row' : ''">
        <FormItem rules="required">
          <Input
            :value="form.startDate"
            :label="isRepeat ? 'Start date' : 'Date'"
            placeholder="Date"
            is-date
            isTextInput
            :dateFormat="dateFormat"
            @input="handleChange('startDate', $event)"
          />
        </FormItem>
        <FormItem>
          <Select
            v-if="isRepeat"
            class="availabilityForm__switch"
            :value="form.endRepeat"
            :options="repeatOptions"
            label="End repeat"
            placeholder="End repeat"
            @input="handleChange('endRepeat', $event)"
          />
        </FormItem>
      </div>
      <FormItem>
        <availabilities-week-repeat
          v-if="isRepeat"
          :value="form.weekdays"
          @input="handleChange('weekdays', $event)"
        />
      </FormItem>
    </template>

    <template v-else>
      <div class="availabilityForm__slots">
        <AvailabilitySlots
          :value="form.slots"
          :product="selectedProduct"
          :isBlurredOutside.sync="isBlurredOutsideSlots"
          @input="handleChange('slots', $event)"
        />
      </div>
      <div class="mb-48" :class="{ 'm-blurred': isBlurredOutsideSlots }">
        <icon-button icon="plus" label-position="left" @click="handleSlotAdd">
          <div class="availabilityForm__add-slots">
            <span>Add</span>
            <NumericCounterInput v-model="addSlotsCount" :min="1" :max="99" />
            <span>time slots</span>
          </div>
        </icon-button>
      </div>
    </template>
    <div
      class="mt-auto"
      :class="{
        availabilityForm__row: activeStep === 'slots',
        'm-blurred': isBlurredOutsideSlots,
      }"
    >
      <Button
        v-if="activeStep === 'slots'"
        is-block
        variant="secondary"
        is-outlined
        class="mt-auto"
        @click="activeStep = 'dates'"
      >
        Back
      </Button>
      <Button
        :is-loading="isSubmitting"
        :is-disabled="invalid"
        is-block
        class="mt-auto"
        @click="handleSave"
      >
        {{ activeStep === "dates" ? "Next" : "Save" }}
      </Button>
    </div>
  </Form>
</template>

<script>
import { mapState } from "vuex";
import AvailabilitiesWeekRepeat from "./AvailabilitiesWeekRepeat";
import { EndRepeatEnum } from "@/helpers/enums";
import {
  getAvailabilitySave,
  getAvailabilitySlotTime,
  getMinutes,
  getTimezoneOffsetHours,
  getVenueTimeFormatHours,
} from "@/helpers/utils";
import { v4 as uuid, validate as uuidValidate } from "uuid";
import moment from "moment";
import NumericCounterInput from "@/components/NumericCounterInput/NumericCounterInput.vue";
import AvailabilitySlots from "@/components/calendar/availabilities/AvailabilitySlots.vue";
import cloneDeep from "lodash.clonedeep";
import dialog from "@/plugins/dialog";

export default {
  name: "AvailabilityForm",
  components: {
    AvailabilitySlots,
    NumericCounterInput,
    AvailabilitiesWeekRepeat,
  },
  props: {
    isSubmitting: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    activeStep: "dates",
    addSlotsCount: 1,
    initialForm: null,
    repeatOptions: [
      {
        name: "1 month",
        value: EndRepeatEnum.ONE,
      },
      {
        name: "2 months",
        value: EndRepeatEnum.TWO,
      },
      {
        name: "3 months",
        value: EndRepeatEnum.THREE,
      },
      {
        name: "6 months",
        value: EndRepeatEnum.SIX,
      },
      {
        name: "9 months",
        value: EndRepeatEnum.NINE,
      },
      {
        name: "12 months",
        value: EndRepeatEnum.TWELVE,
      },
    ],
    isBlurredOutsideSlots: false,
  }),
  created() {
    if (this.productIds.length === 1) {
      this.handleChange("productId", this.productIds[0]);
    }
    this.resetForm();
  },
  beforeDestroy() {
    this.$store.commit("calendar/SET_EDITING_GROUP", null);
  },
  computed: {
    ...mapState({
      dateFormat: (state) => state.venues.selectedVenue.dateFormat,
      products: (state) => state.products.list,
      productIds: (state) => state.calendar.productIds,
      venueUtcOffset: (state) =>
        getTimezoneOffsetHours(state.venues.selectedVenue.timezone),
      editingGroup: (state) => state.calendar.editingGroup,
    }),
    form: {
      get() {
        const startDate = this.editingGroup?.startDate
          ? moment(this.editingGroup.startDate).format(this.dateFormat)
          : "";
        const hoursFormat = getVenueTimeFormatHours();
        const slots = Array.isArray(this.editingGroup?.slots)
          ? this.editingGroup.slots
          : Object.entries(this.editingGroup?.slots || {})
              .sort(([, a], [, b]) => getMinutes(a.from) - getMinutes(b.from))
              .map(([uuid, value]) => {
                if (uuidValidate(uuid)) {
                  const {
                    breakTime,
                    price,
                    fixedPrice,
                    groupsBasedPrice,
                    isPrivate,
                    isVisibleOnline,
                  } = value;
                  const fromMinutes =
                    getMinutes(value.from) + this.venueUtcOffset * 60;
                  const durationMinutes =
                    getMinutes(value.to) - getMinutes(value.from);

                  return {
                    uuid,
                    breakTime: this.getTimeFromMinutes(breakTime),
                    price,
                    fixedPrice,
                    groupsBasedPrice,
                    isPrivate,
                    isVisibleOnline,
                    duration: this.getTimeFromMinutes(durationMinutes),
                    startTime: this.getTimeFromMinutes(
                      fromMinutes,
                      hoursFormat === 12
                    ),
                    activeTime: fromMinutes / 60 >= 12 ? "pm" : "am",
                  };
                }
                return value;
              });

        return { ...this.editingGroup, slots, startDate };
      },
      set(newVal) {
        this.$store.commit("calendar/SET_EDITING_GROUP", newVal);
      },
    },
    isRepeat: {
      get() {
        return !!this.form.endRepeat;
      },
      set(newVal) {
        if (!this.form.id) {
          this.handleChange("endRepeat", newVal ? EndRepeatEnum.ONE : null);
        }
      },
    },
    selectedProduct() {
      return this.products.find(
        (product) => product.id === this.form.productId
      );
    },
    productOptions() {
      return this.products.map((product) => ({
        value: product.id,
        name: product.name,
      }));
    },
  },
  watch: {
    activeStep() {
      if (
        this.activeStep === "slots" &&
        !Object.entries(this.form.slots || {}).length
      ) {
        this.handleChange("slots", [this.createSlot()]);
      }
    },
    "form.id"() {
      this.resetForm();
    },
  },
  methods: {
    resetForm() {
      this.initialForm = cloneDeep(this.form);
    },
    getTimeFromMinutes(time, isAmPm) {
      let hours = Math.floor(time / 60);

      if (isAmPm && hours > 12) {
        hours %= 12;
      }

      const formattedHours = hours >= 10 ? hours : `0${hours}`;
      const minutes = time % 60;
      const formattedMinutes = minutes >= 10 ? minutes : `0${minutes}`;

      if (isNaN(minutes) || isNaN(hours)) return time;

      return `${formattedHours}:${formattedMinutes}`;
    },
    createSlot(data) {
      let slot = { uuid: +new Date(), isVisibleOnline: true };
      if (data) {
        slot = {
          ...slot,
          ...data,
        };
      } else if (this.selectedProduct) {
        if (this.selectedProduct.breakTime) {
          slot.breakTime = this.getTimeFromMinutes(
            this.selectedProduct.breakTime
          );
        }
        if (this.selectedProduct.duration) {
          slot.duration = this.getTimeFromMinutes(
            this.selectedProduct.duration
          );
        }
        if (this.selectedProduct.price) {
          slot.price = this.selectedProduct.price;
        }
        if (this.selectedProduct.fixedPrice) {
          slot.fixedPrice = this.selectedProduct.fixedPrice;
        }
      }

      return slot;
    },
    handleSlotAdd() {
      for (let i = 0; i < +this.addSlotsCount; i++) {
        const lastSlot = this.form.slots[this.form.slots.length - 1];
        let data;

        if (lastSlot) {
          let formattedHours, formattedMinutes, isPm;

          if (lastSlot.startTime?.length === 5) {
            const [startHour, startMinute] = lastSlot.startTime.split(":");
            const [startBreakTimeHour, startBreakTimeMinute] =
              lastSlot.breakTime.split(":");
            const [startDurationHour, startDurationMinute] =
              lastSlot.duration.split(":");
            const hours =
              getAvailabilitySlotTime(
                startHour,
                !lastSlot.activeTime || lastSlot.activeTime === "am"
              ) +
              +startBreakTimeHour +
              +startDurationHour;
            let startMinutes =
              hours * 60 +
              +startMinute +
              +startBreakTimeMinute +
              +startDurationMinute;
            const flooredHours = Math.floor(startMinutes / 60) % 24;
            const hoursFormat = getVenueTimeFormatHours();
            isPm = hoursFormat === 12 && flooredHours >= 12;
            startMinutes = isPm
              ? flooredHours > 12
                ? startMinutes - 12 * 60
                : startMinutes
              : startMinutes;
            const newHours = Math.floor(startMinutes / 60) % 24;
            const newMinutes = startMinutes % 60;
            formattedHours = newHours >= 10 ? newHours : `0${newHours}`;
            formattedMinutes = newMinutes >= 10 ? newMinutes : `0${newMinutes}`;
          }

          data = {
            uuid: `${+new Date()}${i}`,
            startTime: formattedHours
              ? `${formattedHours}:${formattedMinutes}`
              : "",
            activeTime: isPm ? "pm" : "am",
            price: lastSlot.price,
            isPrivate: lastSlot.isPrivate,
            isVisibleOnline: lastSlot.isVisibleOnline,
            fixedPrice: lastSlot.fixedPrice,
            breakTime: lastSlot.breakTime,
            duration: lastSlot.duration,
            groupsBasedPrice: lastSlot.groupsBasedPrice
              ? cloneDeep(lastSlot.groupsBasedPrice)
              : null,
          };
        }

        const slot = this.createSlot(data);
        const slots = this.form.slots;
        slots.push(slot);
        this.handleChange("slots", slots);
      }
    },
    handleChange(field, value) {
      this.form = {
        ...this.form,
        [field]: value,
      };
    },
    isSlotsWillBeDeleted() {
      if (
        moment(this.form.startDate).isAfter(this.initialForm.startDate) ||
        (moment(this.form.startDate).isBefore(this.initialForm.startDate) &&
          this.form.endRepeat <= this.initialForm.endRepeat)
      ) {
        return true;
      } else if (
        this.initialForm.weekdays.some(
          (day) => !this.form.weekdays.includes(day)
        )
      ) {
        return true;
      } else if (
        this.initialForm.slots.some(
          (slot) =>
            !this.form.slots.some((initSlot) => slot.uuid === initSlot.uuid)
        )
      ) {
        return true;
      }
      return false;
    },
    async handleSave() {
      if (this.activeStep === "dates") {
        this.activeStep = "slots";
      } else {
        if (this.form.id && this.initialForm && this.isSlotsWillBeDeleted()) {
          if (
            !(await dialog.confirm({
              title: "Are you sure?",
              message: "This action will delete some of the group slots!",
              okText: "Accept",
              cancelText: "Cancel",
            }))
          ) {
            return;
          }
        }

        const formattedSlots = this.form.slots.reduce((prev, slot) => {
          const slotUuid = uuidValidate(slot.uuid) ? slot.uuid : uuid();
          return {
            ...prev,
            [slotUuid]: getAvailabilitySave(slot, this.venueUtcOffset),
          };
        }, {});

        this.isPrivate = false;
        this.$emit("save", {
          ...this.form,
          startDate: moment(this.form.startDate, this.dateFormat).format(
            "YYYY-MM-DD"
          ),
          weekdays: this.isRepeat ? this.form.weekdays : [],
          endRepeat: this.isRepeat ? this.form.endRepeat : null,
          slots: formattedSlots,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.availabilityForm {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;

  &__slotsLabel {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: $secondary-500;
    margin-bottom: 40px;

    &.standard {
      margin-bottom: 24px;
    }
  }

  &__row {
    display: grid;
    gap: 12px;
    grid-template-columns: 1fr 1fr;

    @media (max-width: $media-laptop - 1) {
      display: flex;
      flex-direction: column;
    }

    button {
      min-width: unset;
    }
  }

  .switch {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  &__tabs {
    display: flex;
    align-items: center;
    gap: 12px;
    color: $secondary-500;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;

    .m-disabled {
      color: $secondary-400;
    }
  }

  &__slots {
    padding: 24px 0;
    border-width: 1px 0;
    border-style: solid;
    border-color: $secondary-300;
  }

  &__add-slots {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}
</style>
