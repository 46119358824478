var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._b({staticClass:"calendarCellSingle",style:({
    top: `${_vm.position}px`,
    height: `${(_vm.duration * _vm.heightCoef) / 60}px`,
  })},'div',_vm.$attrs,false),[(_vm.slotsSlice && _vm.slotsSlice.length > 1)?_c('div',{staticClass:"calendarCellSingle__tabs"},[_vm._l((_vm.slotsSlice),function(slot,index){return _c('div',{key:index,staticClass:"calendarCellSingle__tabs__item",class:{ 'm-active': index + _vm.offset === _vm.primarySlotIndex },style:({ background: _vm.tabHighlightColor(slot) }),on:{"click":function($event){$event.stopPropagation();return _vm.handleSlotSelect(index + _vm.offset)}}},[_c('div',{staticClass:"calendarCellSingle__tabs__item__color",style:({ background: _vm.getGroupColor(slot) || 'black' })})])}),(_vm.slots.length > _vm.limit)?_c('div',{staticClass:"calendarCellSingle__tabs__control"},[_c('Icon',{attrs:{"isClickable":"","name":"arrow-left","size":10,"color":_vm.offset <= 0 ? 'secondary-400' : 'secondary-500'},on:{"click":function($event){$event.stopPropagation();return _vm.updateOffset(-1)}}}),_c('Icon',{attrs:{"isClickable":"","name":"arrow-right","size":10,"color":_vm.offset >= this.slots.length - _vm.limit
            ? 'secondary-400'
            : 'secondary-500'},on:{"click":function($event){$event.stopPropagation();return _vm.updateOffset(1)}}})],1):_vm._e()],2):_vm._e(),_c('div',{staticClass:"calendarCellSingle__wrapper",class:{
      'm-withTabs': _vm.slots && _vm.slots.length > 1,
      'm-preview': _vm.isPreview,
      'm-private': _vm.isPrivate,
    }},[_c('div',{staticClass:"calendarCellSingle__highlight",style:({
        background: _vm.highlightColor,
      })}),_c('div',{staticClass:"calendarCellSingle__timeRow"},[_c('div',{staticClass:"calendarCellSingle__time m-overflowed"},[_c('div',{staticClass:"calendarCellSingle__time__value"},[_vm._v(" "+_vm._s(_vm.time)+" ")])]),_c('div',{staticClass:"calendarCellSingle__duration"},[_c('span',{staticClass:"calendarCellSingle__durationText"},[_vm._v(_vm._s(_vm.duration))]),_c('Icon',{attrs:{"name":"clock","size":10,"color":"secondary-400"}})],1)]),_c('div',{staticClass:"calendarCellSingle__priceRow"},[(_vm.groupColor)?_c('div',{staticClass:"calendarCellSingle__groupColor",style:({ background: _vm.groupColor })}):_vm._e(),(_vm.fixedPrice || _vm.price)?_c('div',{staticClass:"calendarCellSingle__price m-overflowed"},[_vm._v(" $"+_vm._s(_vm.fixedPrice ? `${_vm.fixedPrice} fixed` : `${_vm.price} pp`)+" ")]):_vm._e()]),_c('div',{staticClass:"calendarCellSingle__bookedRow"},[_c('span',{staticClass:"calendarCellSingle__booked m-overflowed",class:{ 'm-overbooked': _vm.capacity && _vm.taken > _vm.capacity }},[_vm._v(" "+_vm._s(_vm.capacity ? `${ _vm.taken > _vm.capacity ? "Overbooked" : "Booked" } ${_vm.taken}/${_vm.capacity}` : "")+" ")]),(_vm.productIcon)?_c('div',{staticClass:"calendarCellSingle__productIcon"},[_c('Icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.productName,
            classes: 'calendarCellSingle__tooltip',
            boundariesElement: 'body',
          }),expression:"{\n            content: productName,\n            classes: 'calendarCellSingle__tooltip',\n            boundariesElement: 'body',\n          }"}],attrs:{"name":_vm.productIcon,"color":"secondary-400","size":12}})],1):_vm._e()]),(_vm.capacity)?_c('div',{staticClass:"calendarCellSingle__completeness",class:{ 'm-overbooked': _vm.taken > _vm.capacity },style:({ width: `${this.completeness}%` })}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }