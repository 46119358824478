var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"time-input",class:{ 'm-nano': _vm.isNano }},[(_vm.label)?_c('p',{staticClass:"time-input__label"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{staticClass:"time-input__control"},[_c('FormItem',{staticClass:"w-100",attrs:{"rules":"required|time"},scopedSlots:_vm._u([{key:"default",fn:function({ isError }){return [_c('Input',{attrs:{"max":5,"is-floating":false,"placeholder":_vm.placeholder,"is-small":_vm.isSmall,"is-mini":_vm.isMini,"is-disabled":_vm.isDisabled,"is-error":isError},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}})]}}])}),(_vm.hasTimeButtons && _vm.hoursFormat === 12)?_c('div',{staticClass:"time-input__time",class:{
        'm-disabled': _vm.isDisabled,
      }},[_c('div',{class:[
          'time-input__time-button',
          {
            'm-active': _vm.innerActiveTime === 'am',
            'm-small': _vm.isSmall,
            'm-mini': _vm.isMini,
            'm-nano': _vm.isNano,
          },
        ],on:{"click":function($event){return _vm.updateActiveTime('am')}}},[_vm._v(" AM ")]),_c('div',{class:[
          'time-input__time-button',
          {
            'm-active': _vm.innerActiveTime === 'pm',
            'm-small': _vm.isSmall,
            'm-mini': _vm.isMini,
            'm-nano': _vm.isNano,
          },
        ],on:{"click":function($event){return _vm.updateActiveTime('pm')}}},[_vm._v(" PM ")])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }