<template>
  <div class="availabilitiesWeekRepeat">
    <div class="availabilitiesWeekRepeat__label">{{ $t(label) }}</div>
    <div class="availabilitiesWeekRepeat__days">
      <div
        v-for="(day, index) in days"
        :key="day"
        :class="[
          'availabilitiesWeekRepeat__day',
          { 'm-selected': innerValue.includes(index) },
        ]"
        @click="handleClick(index)"
      >
        {{ day }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AvailabilitiesWeekRepeat",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "Every week on",
    },
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  data: () => ({
    days: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
  }),
  methods: {
    handleClick(day) {
      const value = [...this.innerValue];

      if (this.innerValue.includes(day)) {
        value.splice(this.innerValue.indexOf(day), 1);
      } else {
        value.push(day);
      }

      this.innerValue = value;
    },
  },
};
</script>
<style lang="scss">
.availabilitiesWeekRepeat {
  display: flex;
  flex-direction: column;

  &__label {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 16px;
    color: var(--color-secondary-500);
  }

  &__days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
  }

  &__day {
    border: 1px solid $secondary-300;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 10px;
    line-height: 26px;
    letter-spacing: 0.025em;
    padding: 6px 8px;
    color: $secondary-500;
    cursor: pointer;
    transition: 0.3s;

    &.m-selected {
      background: $primary;
      color: $white;
    }
  }
}
</style>
