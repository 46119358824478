<template>
  <div class="calendar" :class="{ 'm-weekly': !isMonthly }">
    <div class="calendar__body">
      <calendar-times v-if="!isMonthly" />
      <calendar-view
        v-if="!isMonthly"
        :schedule="schedule"
        :selected-day="selectedDay"
        :is-loading="isLoading"
        @menu-item-select="handleMenuItemSelect"
      />
      <calendar-view-month
        v-else
        :schedule="schedule"
        :selected-day="selectedDay"
        :is-loading="isLoading"
        @click="handleDayClick"
      />
    </div>
    <div class="calendar__loader" v-if="isLoading">
      <div class="overlay-loader">
        <Loader color="primary" size="l" />
      </div>
    </div>
  </div>
</template>
<script>
import CalendarTimes from "./CalendarTimes";
import CalendarView from "./calendarView/CalendarView";
import CalendarViewMonth from "./calendarView/CalendarViewMonth";
import { CALENDAR_CELL_MENU_OPTION_VALUES } from "@/helpers/mocks";
import { mapGetters } from "vuex";

export default {
  name: "Calendar",
  components: {
    CalendarViewMonth,
    CalendarTimes,
    CalendarView,
  },
  props: {
    days: {
      type: Array,
      required: true,
    },
    schedule: {
      type: Array,
      required: true,
    },
    isMonthly: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    selectedDay: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      month: "calendar/getMonth",
    }),
  },
  methods: {
    handleMenuItemSelect(item, slot) {
      switch (item.value) {
        case CALENDAR_CELL_MENU_OPTION_VALUES.CREATE_BOOKING:
          this.$emit("create", slot);
          break;
        case CALENDAR_CELL_MENU_OPTION_VALUES.VIEW_BOOKINGS:
          this.$emit("open", slot);
          break;
        case CALENDAR_CELL_MENU_OPTION_VALUES.EDIT_AVAILABILITY:
          this.$emit("edit-availability", slot);
          break;
        case CALENDAR_CELL_MENU_OPTION_VALUES.CLONE_AVAILABILITY:
          this.$emit("clone-availability", slot);
          break;
      }
    },
    handleDayClick(day) {
      this.$emit("day-select", day.date);
    },
  },
};
</script>
<style lang="scss" scoped>
.calendar {
  flex: 1;
  position: relative;

  &.m-weekly {
    margin-left: -18px;
  }

  @media (max-width: $media-laptop - 1) {
    margin-left: 0;
  }

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;

    .overlay-loader {
      position: sticky;
      height: 100%;
      min-height: 100px;
      max-height: 800px;
    }
  }

  &__body {
    position: relative;
    flex: none;
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }
}
</style>
