<template>
  <div class="availabilitySlotPrice" :class="{ 'm-reverse': isFixed }">
    <input
      v-if="!isGroupPricing"
      class="availabilitySlotPrice__input"
      v-model="innerValue"
    />
    <div
      class="availabilitySlotPrice__type"
      @click="!isGroupPricing && (isFixed = !isFixed)"
    >
      {{ isGroupPricing ? "GROUP" : isFixed ? "FIXED" : "PP" }}
    </div>
  </div>
</template>

<script>
export default {
  name: "AvailabilitySlotPrice",
  props: {
    price: {
      type: [String, Number, null],
    },
    fixedPrice: {
      type: [String, Number, null],
    },
    isGroupPricing: {
      type: Boolean,
    },
  },
  computed: {
    innerValue: {
      get() {
        return this.isFixed ? this.fixedPrice : this.price;
      },
      set(newVal) {
        const cleanValue = newVal.toString().replace(/[^\d+.]/g, "");
        this.$emit(
          `update:${this.isFixed ? "fixedPrice" : "price"}`,
          +cleanValue
        );
      },
    },
    isFixed: {
      get() {
        return this.fixedPrice !== null && this.price === null;
      },
      set(newVal) {
        if (newVal) {
          this.$emit("update:fixedPrice", +this.price || 0);
          this.$emit("update:price", null);
        } else {
          this.$emit("update:price", +this.fixedPrice || 0);
          this.$emit("update:fixedPrice", null);
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.availabilitySlotPrice {
  display: flex;
  flex-direction: row;
  gap: 1px;
  border: 2px solid $secondary-400;
  border-radius: 8px;
  overflow: hidden;
  background: $secondary-400;
  width: 62px;

  &.m-reverse {
    flex-direction: row-reverse;
  }

  &__input {
    color: $secondary-500;
    text-align: center;
    font-size: 12px;
    line-height: 28px;
    border: none;
    width: 32px;
    height: 28px;
    background: $white;
  }

  &__type {
    cursor: pointer;
    background: $primary;
    color: $white;
    text-align: center;
    font-size: 8px;
    text-transform: uppercase;
    height: 28px;
    line-height: 28px;
    flex: 1;
  }
}
</style>
