<template>
  <div class="calendarView">
    <div class="calendarView__lines">
      <div class="calendarView__line" v-for="line in 24" :key="line" />
    </div>
    <div
      v-for="(day, index) in sortedSchedule"
      :key="`day-${index}`"
      :class="[
        'calendarView__day',
        {
          'm-active': day && checkActive(day.date, selectedDay),
        },
      ]"
    >
      <template v-if="!isLoading">
        <template v-if="!day.isNonWorking">
          <ContextMenu
            v-for="(slots, key) in groupDuplicates(day.slots)"
            :key="`slot-${key}`"
            :options="
              getContextMenu(
                slots[
                  duplicateGroupsPrimarySlotIndexMap[`${day.date}-${key}`] || 0
                ]
              )
            "
            @select="
              $emit(
                'menu-item-select',
                $event,
                slots[
                  duplicateGroupsPrimarySlotIndexMap[`${day.date}-${key}`] || 0
                ]
              )
            "
          >
            <calendar-cell-single
              class="calendarView__cell"
              :slots="slots"
              :height-coef="heightCoef"
              :primary-slot-index.sync="
                duplicateGroupsPrimarySlotIndexMap[`${day.date}-${key}`]
              "
            />
          </ContextMenu>
          <calendar-cell-single
            v-for="slot in getPreviewSlots(day.date)"
            :key="slot.uuid"
            class="calendarView__cell"
            :slots="[slot]"
            :height-coef="heightCoef"
            is-preview
          />
        </template>
        <template v-else>
          <div v-for="i in 24" :key="i" class="calendarView__nonWorking">
            {{ i % 2 ? $t("Blackout date") : "" }}
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import CalendarCellSingle from "../calendarCell/CalendarCellSingle";
import {
  checkActive,
  getAvailabilitySave,
  getMinutes,
  getTimezoneOffsetHours,
} from "@/helpers/utils";
import { CALENDAR_CELL_MENU_OPTION_VALUES } from "@/helpers/mocks";
import moment from "moment";
import { mapGetters, mapState } from "vuex";

const isSlotIncludesOther = (slot, otherSlot) => {
  return (
    getMinutes(slot.from) <= getMinutes(otherSlot.from) &&
    getMinutes(slot.to) >= getMinutes(otherSlot.to)
  );
};

export default {
  name: "CalendarView",
  components: { CalendarCellSingle },
  props: {
    schedule: {
      type: Array,
      required: true,
    },
    selectedDay: {
      type: [String, Boolean],
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checkActive,
      heightCoef: 77,
      duplicateGroupsPrimarySlotIndexMap: {},
    };
  },
  computed: {
    ...mapState({
      firstSlot: (state) => state.calendar.calendar.firstSlot,
      selectedProduct: (state) => state.products.selectedProduct,
      venue: (state) => state.venues.selectedVenue,
    }),
    ...mapGetters({
      checkPermission: "users/checkPermission",
    }),
    sortedSchedule() {
      const sorted = [...this.schedule].sort(
        (a, b) => +new Date(a.date) - +new Date(b.date)
      );

      return ["xs", "sm"].includes(this.$mq)
        ? [
            sorted.find(
              (day) =>
                day.date ===
                (this.selectedDay ||
                  moment.utc().startOf("date").format("YYYY-MM-DD"))
            ),
          ]
        : sorted;
    },
  },
  created() {
    this.scrollToFirst();
  },
  watch: {
    firstSlot(val) {
      if (val) {
        this.scrollToFirst();
      }
    },
  },
  methods: {
    getPreviewSlots(date) {
      const group = this.$store.state.calendar.editingGroup;
      if (group && group.startDate && group.slots?.length) {
        const dateMoment = moment(date);
        const startMoment = moment(group.startDate, this.venue.dateFormat);
        const endMoment =
          group.endRepeat &&
          moment(group.startDate).add(group.endRepeat, "months");

        if (
          (endMoment &&
            startMoment.isSameOrBefore(dateMoment, "date") &&
            endMoment.isSameOrAfter(dateMoment) &&
            group.weekdays?.includes(dateMoment.weekday())) ||
          (!endMoment && startMoment.isSame(dateMoment, "date"))
        ) {
          return group.slots
            .map((slot) => {
              return getAvailabilitySave(
                slot,
                getTimezoneOffsetHours(this.venue.timezone)
              );
            })
            .filter(Boolean);
        }
      }
      return [];
    },
    groupDuplicates(slots) {
      return slots.reduce((prev, slot) => {
        const slotKey = `${getMinutes(slot.from)}-${getMinutes(slot.to)}`;
        const [key] = (!prev[slotKey] &&
          Object.entries(prev).find(([, slots]) => {
            return slots.some(
              (otherSlot) =>
                isSlotIncludesOther(slot, otherSlot) ||
                isSlotIncludesOther(otherSlot, slot)
            );
          })) || [slotKey];
        prev[key] = prev[key] ?? [];
        prev[key].push(slot);
        return prev;
      }, {});
    },
    scrollToFirst() {
      if (this.firstSlot) {
        const utcOffset = getTimezoneOffsetHours(this.venue.timezone);
        const height =
          ((getMinutes(this.firstSlot) + utcOffset * 60) * this.heightCoef) /
            60 -
          60;

        setTimeout(() => {
          document?.querySelector(".space-container")?.scrollTo({
            top: height,
            behavior: "smooth",
          });
        }, 0);
      }
    },
    getContextMenu(slot) {
      const isPastSlot = moment
        .utc(slot.dateIso)
        .hour(slot.from.hours - this.selectedProduct.cutoffTime)
        .minutes(slot.from.minutes)
        .isSameOrBefore(moment.utc());
      return [
        !isPastSlot &&
          slot.taken < slot.capacity &&
          this.checkPermission("bookings.create") && {
            value: CALENDAR_CELL_MENU_OPTION_VALUES.CREATE_BOOKING,
            name: "Create booking",
          },
        slot.taken && {
          value: CALENDAR_CELL_MENU_OPTION_VALUES.VIEW_BOOKINGS,
          name: "View bookings",
        },
        this.checkPermission("calendar.edit") &&
          slot.groupId && {
            value: CALENDAR_CELL_MENU_OPTION_VALUES.EDIT_AVAILABILITY,
            name: "Edit availability",
          },
        this.checkPermission("calendar.create") &&
          slot.groupId && {
            value: CALENDAR_CELL_MENU_OPTION_VALUES.CLONE_AVAILABILITY,
            name: "Clone availability",
          },
      ].filter((option) => !!option);
    },
  },
};
</script>
<style lang="scss">
.calendarView {
  display: inline-flex;
  margin-left: 24px;
  min-width: calc(100% - 24px);
  width: calc(100% - 24px);
  position: relative;
  border-bottom: 1px solid $secondary-300;

  @media (max-width: $media-laptop - 1) {
    margin-left: 0;
    border: none;
    width: auto;
    min-width: auto;
    flex: 1;
  }

  &__lines {
    @media (min-width: $media-laptop) {
      &::after {
        content: "";
        border-right: 1px solid $secondary-300;
        pointer-events: none;
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
    @media (max-width: $media-laptop - 1) {
      padding-left: 12px;
    }
  }

  &__line {
    height: 77px;

    &::after {
      content: "";
      border-bottom: 1px dashed $secondary-600;
      pointer-events: none;
      position: absolute;
      width: 100%;
      z-index: 1;

      @media (max-width: $media-laptop - 1) {
        width: calc(100% - 12px);
      }
    }

    &:first-child {
      &::after {
        border: none;
      }
    }
  }

  &__days {
    flex: 1 0 auto;
    position: relative;
  }

  &__day {
    border-right: 1px solid $secondary-600;
    width: calc(100% / 7);
    padding: 0 6px;
    position: relative;

    @media (max-width: $media-laptop - 1) {
      width: 100%;
      border: none;
    }

    @media (min-width: $media-laptop) {
      &:nth-child(2) {
        border-left: 1px solid $secondary-300;
      }

      &:last-of-type {
        border-right: 1px solid $secondary-300;
      }

      &.m-active {
        background-color: $secondary-700;
      }
    }
  }

  &__cell {
    width: calc(100% - 12px);
  }

  &__nonWorking {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    opacity: 0.5;
    text-transform: uppercase;
    color: $secondary-600;

    height: 77px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
