<template>
  <div class="availability-card">
    <div class="availability-card__header">
      <div
        class="availability-card__header__color"
        :style="{ background: group.color }"
      />
      <span>{{ group.label }}</span>
    </div>
    <div class="availability-card__dates">
      <span>{{ group.startDate }}</span>
      <div v-if="group.endDate" class="availability-card__dates__line" />
      <span>{{ group.endDate }}</span>
    </div>
    <div v-if="group.weekdays?.length" class="availability-card__week">
      <span
        v-for="(weekday, index) in WEEKDAYS_SHORT"
        :key="weekday"
        :class="{ 'm-active': group.weekdays.includes(index) }"
      >
        {{ weekday }}
      </span>
    </div>
    <p v-if="group.productName" class="availability-card__product">
      {{ group.productName }}
    </p>
  </div>
</template>

<script>
import { WEEKDAYS_SHORT } from "@/helpers/const";

export default {
  name: "AvailabilityCard",
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      WEEKDAYS_SHORT,
    };
  },
};
</script>

<style lang="scss" scoped>
.availability-card {
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 4px;
  border: 1px solid $secondary-300;
  cursor: pointer;

  &__header {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    color: $secondary-500;

    &__color {
      width: 6px;
      height: 6px;
      border-radius: 100%;
    }
  }

  &__dates {
    display: flex;
    align-items: center;
    gap: 12px;
    color: $secondary-400;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;

    &__line {
      height: 1px;
      flex: 1;
      background: $secondary-400;
    }
  }

  &__week {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    color: $secondary-500;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.25px;
    text-transform: uppercase;

    .m-active {
      color: $primary;
    }
  }

  &__product {
    color: $primary;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
  }
}
</style>
