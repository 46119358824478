<template>
  <div :class="['calendarHeader', { 'm-monthly': isMonthly }]">
    <div
      :class="[
        'calendarHeader__day',
        {
          'm-active': !isMonthly && day && checkActive(day.date, selectedDay),
          'm-border': isMonthly && isShowBorder,
        },
      ]"
      v-for="(day, index) in days"
      :key="index"
      @click="['xs', 'sm'].includes($mq) && $emit('header-click', day)"
    >
      <div class="calendarHeader__wrapper">
        <span
          :class="[
            'calendarHeader__text',
            { 'm-empty': checkIsNotWorking(day.date) },
          ]"
        >
          {{ getTopDay(day.date) }}
        </span>
        <div
          v-if="['xs', 'sm'].includes($mq) && !isMonthly"
          :class="[
            'calendarHeader__dayButton',
            {
              'm-active':
                !isMonthly && day && checkActive(day.date, selectedDay),
            },
          ]"
        >
          {{ getDay(day.date) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { checkActive } from "@/helpers/utils";
import { mapState } from "vuex";

export default {
  name: "CalendarHeader",
  props: {
    days: {
      type: Array,
      required: true,
    },
    selectedDay: {
      type: [String, Boolean],
      default: false,
    },
    isMonthly: {
      type: Boolean,
      default: false,
    },
    isShowBorder: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    checkActive,
  }),
  computed: {
    ...mapState({
      calendar: (state) => state.calendar.calendar.dates,
    }),
  },
  methods: {
    checkIsNotWorking(date) {
      return (
        !this.isMonthly && this.calendar && !this.calendar[date]?.isWorking
      );
    },
    getDay(date) {
      return moment(date).format("D");
    },
    getWeekDay(date) {
      return moment(date).format("ddd").toUpperCase();
    },
    getTopDay(date) {
      return ["xs", "sm"].includes(this.$mq) || this.isMonthly
        ? this.getWeekDay(date)
        : `${this.getWeekDay(date)}. ${this.getDay(date)}`;
    },
  },
};
</script>
<style lang="scss">
.calendarHeader {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-left: 6px;
  border-bottom: 1px solid $secondary-600;
  z-index: 5;

  @media (max-width: $media-laptop - 1) {
    margin-left: 0;
    border: none;
    padding-bottom: 16px;
    border-bottom: 1px solid $secondary-600;
  }

  &.m-monthly {
    margin-left: 0;
  }

  &__wrapper {
    @media (max-width: $media-laptop - 1) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__day {
    height: 44px;
    border-right: 1px solid $secondary-600;
    border-top: 1px solid $secondary-300;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $media-laptop - 1) {
      border: none;
      padding: 0;
      height: auto;
    }

    @media (min-width: $media-laptop) {
      &:first-of-type {
        border-left: 1px solid $secondary-300;
      }

      &:last-of-type {
        border-right: 1px solid $secondary-300;
      }

      &.m-border {
        border-bottom: 1px solid $secondary-300;
      }

      &.m-active {
        background-color: $secondary-700;

        .calendarHeader__text {
          color: $primary;
        }
      }
    }
  }

  &__text {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: $secondary-500;

    &.m-empty {
      color: #2c3b557f;
    }

    @media (max-width: $media-laptop - 1) {
      font-weight: 600;
      font-size: 10px;
      line-height: 24px;
      letter-spacing: 0.025em;
    }
  }

  &__dayButton {
    font-weight: 600;
    font-size: 10px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.025em;
    color: rgba(44, 59, 85, 0.4);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;

    &.m-active {
      color: $white;
      background-color: $primary;
    }
  }

  &__icon {
    margin-left: 4px;
  }
}
</style>
