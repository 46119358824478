<template>
  <Form class="availabilitySlots">
    <div
      class="availabilitySlots__header"
      :class="{ 'm-center': header.isCenter }"
      v-for="header in headers"
      :key="header.key"
      v-tooltip.left-end="{
        content: header.tooltip,
        classes: 'availabilitySlots__tooltip',
        boundariesElement: 'body',
      }"
    >
      <Icon v-if="header.icon" :name="header.icon" :size="16" />
      <span v-else-if="header.label">{{ header.label }}</span>
    </div>
    <template v-for="(slot, index) in innerValue">
      <TimeInput
        :key="`${slot.uuid}-startTime`"
        :value="slot.startTime"
        placeholder="HH:MM"
        has-time-buttons
        :active-time="slot.activeTime"
        :gap="24"
        :is-nano="true"
        class="availabilitySlots__timeWrapper m-flex"
        :class="getBlurredClasses(slot.uuid)"
        @input="handleInput($event, 'startTime', index)"
        @update:activeTime="handleInput($event, 'activeTime', index)"
      />
      <TimeInput
        :key="`${slot.uuid}-duration`"
        :value="slot.duration"
        placeholder="HH:MM"
        :is-nano="true"
        :class="getBlurredClasses(slot.uuid)"
        @input="handleInput($event, 'duration', index)"
      />
      <TimeInput
        :key="`${slot.uuid}-breakTime`"
        :value="slot.breakTime"
        placeholder="HH:MM"
        :is-nano="true"
        :class="getBlurredClasses(slot.uuid)"
        @input="handleInput($event, 'breakTime', index)"
      />
      <div
        :key="`${slot.uuid}-price`"
        class="availabilitySlots__priceWrapper"
        :class="getBlurredClasses(slot.uuid)"
      >
        <AvailabilitySlotPrice
          :price="slot.price"
          :fixedPrice="slot.fixedPrice"
          :is-group-pricing="!!slot.groupsBasedPrice"
          @update:price="handleInput($event, 'price', index)"
          @update:fixedPrice="handleInput($event, 'fixedPrice', index)"
        />
        <div
          v-if="isGroupsBasedAvailable"
          class="availabilitySlots__priceSettings"
          :class="{
            'm-active': groupsBasedPriceSlotId === slot.uuid,
          }"
          v-tooltip="{
            content: 'Group pricing',
            boundariesElement: 'body',
            classes: 'availabilitySlots__tooltip',
          }"
          @click="
            togglePriceSettings(slot.uuid, groupsBasedPriceSlotId !== slot.uuid)
          "
        >
          <Icon name="settings" color="white" :size="10" />
        </div>
        <div
          v-if="groupsBasedPriceSlotId === slot.uuid"
          class="availabilitySlots__priceArrow"
        />
      </div>
      <Checkbox
        :key="`${slot.uuid}-isVisibleOnline`"
        :class="getBlurredClasses(slot.uuid)"
        :value="slot.isVisibleOnline"
        @input="handleInput($event, 'isVisibleOnline', index)"
      />
      <Checkbox
        :key="`${slot.uuid}-isPrivate`"
        :class="getBlurredClasses(slot.uuid)"
        :value="slot.isPrivate"
        @input="handleInput($event, 'isPrivate', index)"
      />
      <div :key="`${slot.uuid}-remove`" :class="getBlurredClasses(slot.uuid)">
        <Icon
          name="close"
          :color="innerValue.length > 1 ? 'secondary-400' : 'secondary-300'"
          :style="{
            cursor: innerValue.length > 1 ? 'pointer' : 'not-allowed',
          }"
          :size="18"
          @click="innerValue.length > 1 && handleRemove(index)"
        />
      </div>
      <div
        v-if="isGroupsBasedAvailable && groupsBasedPriceSlotId === slot.uuid"
        :key="`${slot.uuid}-groupsBasedPrice`"
        class="availabilitySlots__groupsBasedPrice"
      >
        <h5 class="mb-12 availabilitySlots__groupsBasedPrice__title">
          Group pricing
          <template v-if="groupsBasedPriceSlot.startTime">
            for
            <span class="availabilitySlots__groupsBasedPrice__title__time">
              {{ groupsBasedPriceSlot.startTime }}
              {{ groupsBasedPriceSlot.activeTime }}
            </span>
          </template>
        </h5>
        <Checkbox
          class="mb-12"
          :value="!!tempGroupsBasedPrice"
          @input="toggleGroupPricing($event, index)"
        >
          Enable group pricing for this slot
        </Checkbox>
        <p class="availabilitySlots__groupsBasedPrice__disclaimer mb-24">
          You can manage groups on the
          <router-link
            :to="{
              name: 'EditProduct',
              params: {
                id: product.id,
              },
            }"
          >
            product page
          </router-link>
        </p>
        <div
          v-if="tempGroupsBasedPrice"
          class="availabilitySlots__groupsBasedPrice__rows mb-12"
        >
          <div
            class="availabilitySlots__header"
            :class="{ 'm-center': header.isCenter }"
            v-for="header in groupsBasedPriceHeaders"
            :key="header.key"
          >
            <span v-if="header.label">{{ header.label }}</span>
          </div>
          <template v-for="group in product.groups">
            <div :key="`${group.uuid}-people`">
              {{ group.minimum }}-{{ group.maximum }} people
            </div>
            <div
              :key="`${group.uuid}-line`"
              class="availabilitySlots__groupsBasedPrice__rows__line"
            />
            <AvailabilitySlotPrice
              :key="`${group.uuid}-price`"
              :price.sync="tempGroupsBasedPrice[group.uuid].price"
              :fixed-price.sync="tempGroupsBasedPrice[group.uuid].fixedPrice"
            />
            <NumericCounterInput
              :key="`${group.uuid}-deposit`"
              v-model="tempGroupsBasedPrice[group.uuid].personsToDeposit"
              :min="0"
              :max="group.maximum"
            />
          </template>
        </div>
        <p
          class="availabilitySlots__groupsBasedPrice__disclaimer availabilitySlots__groupsBasedPrice__depositInfo mb-12"
          @click="$modal.show('depositInfoModal')"
        >
          How group pricing deposit works
        </p>
        <div class="availabilitySlots__groupsBasedPrice__actions">
          <Button is-small @click="saveGroupsBasedPrice">Save</Button>
          <Button
            variant="secondary"
            is-outlined
            is-small
            @click="closeGroupsBasedPrice"
          >
            Cancel
          </Button>
        </div>
      </div>
    </template>
    <CustomModal name="depositInfoModal">
      <div class="availabilitySlots__depositInfoModal__close">
        <Icon
          name="close"
          is-clickable
          color="secondary-500"
          @click="$modal.hide('depositInfoModal')"
        />
      </div>
      <div class="availabilitySlots__depositInfoModal">
        <h3 class="mb-24">How group pricing deposit works</h3>
        <p class="mb-12">
          You can set the number of people for whom the customer must make a
          deposit in order to make a booking.
        </p>
        <ul>
          <li>To disable deposit, you can set 0.</li>
          <li>
            To enable partial deposit, you can set any desired number within the
            allowed range.
          </li>
          <li>
            To enable full deposit, you can set the deposit to the maximum group
            size. During the booking process this number will be adjusted if
            fewer players are specified.
          </li>
        </ul>
      </div>
    </CustomModal>
  </Form>
</template>
<script>
import { mapState } from "vuex";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { getCurrencyByCountryCode } from "@/helpers/utils";
import TimeInput from "@/components/common/TimeInput";
import AvailabilitySlotPrice from "@/components/calendar/availabilities/AvailabilitySlotPrice.vue";
import { BookingPricingTypeEnum } from "@/helpers/enums";
import cloneDeep from "lodash.clonedeep";
import NumericCounterInput from "@/components/NumericCounterInput/NumericCounterInput.vue";

export default {
  name: "AvailabilitySlots",
  components: {
    NumericCounterInput,
    AvailabilitySlotPrice,
    TimeInput,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    product: {
      type: Object,
      required: true,
    },
    isBlurredOutside: {
      type: Boolean,
    },
  },
  data() {
    return {
      headers: [
        {
          key: "startTime",
          label: "Start time",
        },
        {
          key: "duration",
          label: "Duration",
          isCenter: true,
        },
        {
          key: "breakTime",
          label: "Break time",
        },
        {
          key: "price",
          label: "Price",
          isCenter: true,
        },
        {
          key: "visible",
          icon: "eye",
          tooltip: "Visible online",
          isCenter: true,
        },
        {
          key: "bookable",
          icon: "circle-strikethrough",
          tooltip: "Unbookable online",
          isCenter: true,
        },
        {
          key: "remove",
        },
      ],
      groupsBasedPriceHeaders: [
        {
          key: "group",
          label: "Group",
        },
        {
          key: "line",
        },
        {
          key: "price",
          label: "Price",
          isCenter: true,
        },
        {
          key: "deposit",
          label: "Deposit",
          isCenter: true,
        },
      ],
      tempGroupsBasedPrice: null,
      groupsBasedPriceSlotId: null,
      isDepositInfoOpen: false,
    };
  },
  computed: {
    ...mapState({
      products: (state) => state.products.list,
      currencySymbol: (state) =>
        getCurrencyByCountryCode(state.venues.selectedVenue?.country).symbol,
    }),
    isGroupsBasedAvailable() {
      return (
        this.product?.typeOfPricing === BookingPricingTypeEnum.GROUP_RANGE &&
        !!this.product?.groups?.length
      );
    },
    groupsBasedPriceSlot() {
      return (
        this.groupsBasedPriceSlotId &&
        this.innerValue.find(
          (slot) => slot.uuid === this.groupsBasedPriceSlotId
        )
      );
    },
    dollarsMask() {
      return createNumberMask({
        allowDecimal: true,
        prefix: this.currencySymbol,
      });
    },
    innerValue: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
  },
  watch: {
    groupsBasedPriceSlotId(slotId) {
      this.$emit("update:isBlurredOutside", !!slotId);
    },
  },
  methods: {
    getBlurredClasses(slotId) {
      return {
        "m-blurred":
          this.groupsBasedPriceSlotId && this.groupsBasedPriceSlotId !== slotId,
      };
    },
    handleInput(value, name, index) {
      const copy = [...this.innerValue];
      copy[index][name] = value;
      this.innerValue = copy;
    },
    handleRemove(index) {
      this.innerValue = this.innerValue.toSpliced(index, 1);
      this.closeGroupsBasedPrice();
    },
    togglePriceSettings(slotId, isActive) {
      const slotIndex = this.innerValue.findIndex(
        (slot) => slot.uuid === slotId
      );
      if (slotIndex > -1) {
        if (isActive) {
          this.groupsBasedPriceSlotId = slotId;
          if (this.innerValue[slotIndex].groupsBasedPrice) {
            this.tempGroupsBasedPrice = cloneDeep(
              this.innerValue[slotIndex].groupsBasedPrice
            );
          }
        } else {
          this.closeGroupsBasedPrice();
        }
      }
    },
    closeGroupsBasedPrice() {
      this.groupsBasedPriceSlotId = null;
      this.tempGroupsBasedPrice = null;
    },
    saveGroupsBasedPrice() {
      const slotIndex = this.innerValue.findIndex(
        (slot) => slot.uuid === this.groupsBasedPriceSlotId
      );
      if (this.tempGroupsBasedPrice) {
        this.handleInput(
          this.tempGroupsBasedPrice,
          "groupsBasedPrice",
          slotIndex
        );
        this.handleInput(null, "price", slotIndex);
        this.handleInput(null, "fixedPrice", slotIndex);
      } else {
        this.clearGroupsBasedPrice();
      }
      this.closeGroupsBasedPrice();
    },
    clearGroupsBasedPrice() {
      const slotIndex = this.innerValue.findIndex(
        (slot) => slot.uuid === this.groupsBasedPriceSlotId
      );
      this.handleInput(null, "groupsBasedPrice", slotIndex);
      this.handleInput(this.product.price, "price", slotIndex);
      this.handleInput(this.product.fixedPrice, "fixedPrice", slotIndex);
    },
    toggleGroupPricing(isEnabled, slotIndex) {
      if (isEnabled) {
        if (!this.innerValue[slotIndex].groupsBasedPrice) {
          this.tempGroupsBasedPrice = this.product?.groups?.reduce(
            (prev, group) => {
              return {
                ...prev,
                [group.uuid]: {
                  price: this.product.price,
                  fixedPrice: this.product.fixedPrice,
                  personsToDeposit: group.personsToDeposit,
                },
              };
            },
            {}
          );
        } else {
          this.tempGroupsBasedPrice = cloneDeep(
            this.innerValue[slotIndex].groupsBasedPrice
          );
        }
      } else {
        this.tempGroupsBasedPrice = null;
      }
    },
  },
};
</script>

<style lang="scss">
.availabilitySlots__tooltip {
  z-index: 3000;
}
</style>

<style lang="scss" scoped>
.availabilitySlots {
  display: grid;
  grid-template-columns: repeat(3, 1fr) repeat(4, max-content);
  gap: 4px;
  align-items: center;

  &__header {
    color: $secondary-500;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    white-space: nowrap;
    display: flex;
    align-items: center;

    &.m-center {
      justify-content: center;
    }
  }

  &__priceWrapper {
    position: relative;
  }

  &__priceSettings {
    position: absolute;
    right: -4px;
    top: -4px;
    background: $secondary-400;
    cursor: pointer;
    border-radius: 12px;
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.m-active {
      background: $secondary-500;
    }

    &__tooltip {
      z-index: 1000;
    }
  }

  &__priceArrow {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid $secondary-300;
  }

  &__groupsBasedPrice {
    grid-column: 1 / -1;
    padding: 12px 0;
    border-width: 1px 0;
    border-style: solid;
    border-color: $secondary-300;

    &__title {
      color: $secondary-500;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;

      &__time {
        color: $secondary-400;
        font-weight: 400;
      }
    }

    &__disclaimer {
      font-size: 10px;
      color: $secondary-400;
      font-weight: 400;

      & > a {
        color: inherit;
      }
    }

    &__depositInfo {
      text-decoration: underline;
      cursor: pointer;
      width: max-content;
    }

    &__rows {
      display: grid;
      grid-template-columns: max-content 1fr max-content max-content;
      gap: 8px;
      font-size: 10px;
      font-weight: 500;
      align-items: center;

      &__line {
        height: 1px;
        width: 100%;
        background: $secondary-400;
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: 12px;

      & > button {
        min-width: unset;
        flex: 1;
      }
    }
  }

  &__depositInfoModal {
    font-size: 14px;
    font-weight: 400;
    color: $secondary-500;
    padding: 40px;

    &__close {
      position: absolute;
      top: 16px;
      right: 16px;
    }
  }
}
</style>
