<template>
  <div class="calendar-page">
    <div class="calendar-page__calendar">
      <div class="calendar-sticky">
        <calendar-control
          :month="month"
          :back-month="backMonth"
          @change-month="handleChangeWeek"
          @non-working-days-click="$modal.show('calendarNonWorkingDaysModal')"
          @toggleAvailabilityDrawer="SET_IS_DRAWER_OPEN(!isDrawerOpen)"
        />
        <calendar-header
          :days="days"
          :selected-day="selectedDay"
          :is-monthly="isMonthly"
          :is-show-border="isLoading"
          @header-click="handleDaySelect($event.date)"
        />
      </div>
      <calendar
        :is-monthly="isMonthly"
        :is-loading="isLoading"
        :days="days"
        :schedule="calendarMonth"
        :selected-day="selectedDay"
        @day-select="handleDaySelect"
        @create="handleCreate"
        @open="handleOpen"
        @edit-availability="handleEditAvailability"
        @clone-availability="handleEditAvailability($event, true)"
      />
      <Guard permission="calendar.setNonWorkingDays" v-slot="{ isAvailable }">
        <calendar-select-days-modal
          v-if="isAvailable && singleProduct"
          title="Select blackout dates"
          :value="singleProduct.nonWorkingDays"
          :is-submitting="isNonWorkingDaysSubmitting"
          @input="handleNonWorkingDaysSave"
        />
      </Guard>
      <div class="calendar__fixed" data-v-step="29" />
    </div>
    <div class="calendar-page__drawerWrapper">
      <CalendarDrawer
        :isSubmitting="
          isAvailableCreating || isAvailableEditing || isAvailableDeleting
        "
        :is-loading="isLoadingGroups"
        class="calendar-page__drawer"
        @close="SET_IS_DRAWER_OPEN(false)"
        @start-create="handleStartCreateAvailability"
        @create="handleCreateGroup"
        @update="handleEditGroup"
        @delete="handleDeleteGroup"
      />
    </div>
  </div>
</template>
<script>
import Calendar from "@/components/calendar/Calendar";
import CalendarControl from "@/components/calendar/CalendarControl";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { CALENDAR_VIEW_TYPE_ENUM } from "@/helpers/mocks";
import CalendarSelectDaysModal from "@/components/calendar/modals/CalendarSelectDaysModal.vue";
import dialog from "@/plugins/dialog";
import moment from "moment";
import { getMinutes, getTimezoneOffsetHours } from "@/helpers/utils";
import axios from "axios";
import CalendarHeader from "@/components/calendar/CalendarHeader";
import Guard from "@/components/common/Guard";
import CalendarService from "@/api/services/CalendarService";
import CalendarDrawer from "@/components/calendar/CalendarDrawer.vue";
import { eventBus } from "@/helpers/eventBus";

export default {
  name: "CalendarPage",
  components: {
    CalendarDrawer,
    Guard,
    CalendarHeader,
    CalendarSelectDaysModal,
    CalendarControl,
    Calendar,
  },
  data: () => ({
    isLoading: false,
    isLoadingGroups: false,
    editingSlot: null,
    isAvailableCreating: false,
    isAvailableDeleting: false,
    isAvailableEditing: false,
    isNonWorkingDaysSubmitting: false,
  }),
  computed: {
    ...mapState({
      isMonthly: (state) =>
        state.calendar.view === CALENDAR_VIEW_TYPE_ENUM.MONTH,
      selectedDay: (state) => state.calendar.date,
      products: (state) => state.products.list,
      selectedProduct: (state) => state.products.selectedProduct,
      calendar: (state) => state.calendar.calendar,
      currentMonth: (state) => state.calendar.month,
      venue: (state) => state.venues.selectedVenue,
      groups: (state) => state.calendar.groups,
      editingGroup: (state) => state.calendar.editingGroup,
      productIds: (state) => state.calendar.productIds,
      isDrawerOpen: (state) => state.calendar.isDrawerOpen,
    }),
    ...mapGetters({
      week: "calendar/getCurrentWeek",
      month: "calendar/getMonth",
      calendarMonth: "calendar/getCalendarMonth",
    }),
    backMonth() {
      return moment(this.currentMonth, "YYYY-MM").format("MMMM");
    },
    days() {
      return this.week;
    },
    singleProduct() {
      return this.productIds.length === 1
        ? this.products.find((item) => item.id === this.productIds[0])
        : null;
    },
  },
  async created() {
    if (!this.productIds?.length && this.selectedProduct) {
      this.$store.commit("calendar/SET_PRODUCT_IDS", [
        this.selectedProduct?.id,
      ]);
    }
    await this.getCalendar();
  },
  watch: {
    async productIds() {
      await this.getCalendar();
    },
    async currentMonth() {
      await this.getCalendar();
    },
    async week(newVal, oldVal) {
      let isChanged = false;

      newVal.forEach((day) => {
        if (!oldVal.find((newDay) => newDay.date === day.date))
          isChanged = true;
      });

      if (!isChanged) return;

      await this.getCalendar();
    },
    async isMonthly() {
      await this.getCalendar();
    },
  },
  methods: {
    ...mapActions({
      changeWeek: "calendar/changeWeek",
      changeMonth: "calendar/changeMonth",
      createAvailability: "calendar/createAvailability",
      setNonWorkingDays: "calendar/setNonWorkingDays",
      fetchCalendar: "calendar/fetchCalendar",
      updateSlot: "calendar/updateSlot",
      deleteSlot: "calendar/deleteSlot",
      fetchGroupById: "calendar/fetchGroupById",
      fetchGroups: "calendar/fetchGroups",
    }),
    ...mapMutations({
      SET_DATE: "calendar/SET_DATE",
      SET_VIEW: "calendar/SET_VIEW",
      SET_SELECTED_PRODUCT: "products/SET_SELECTED_PRODUCT",
      SET_EDITING_GROUP: "calendar/SET_EDITING_GROUP",
      SET_IS_DRAWER_OPEN: "calendar/SET_IS_DRAWER_OPEN",
    }),
    async getCalendar() {
      await Promise.all([
        this.getSlotGroups(),
        this.isMonthly ? this.getCalendarMonth() : this.getCalendarWeek(),
      ]);
    },
    async getSlotGroups() {
      try {
        this.isLoadingGroups = true;
        await this.fetchGroups();
      } finally {
        this.isLoadingGroups = false;
      }
    },
    async getCalendarMonth() {
      this.isLoading = true;
      let isCanceled = false;

      try {
        await this.fetchCalendar({
          from: moment(this.currentMonth, "YYYY-MM")
            .startOf("month")
            .format("YYYY-MM-DD"),
          to: moment(this.currentMonth, "YYYY-MM")
            .endOf("month")
            .format("YYYY-MM-DD"),
          limit: 3,
        });
      } catch (e) {
        isCanceled = axios.isCancel(e);
      } finally {
        if (!isCanceled) {
          this.isLoading = false;
        }
      }
    },
    async getCalendarWeek() {
      this.isLoading = true;
      let isCanceled = false;

      try {
        await this.fetchCalendar({
          from: this.week[0].date,
          to: this.week[this.week.length - 1].date,
          limit: undefined,
        });
      } catch (e) {
        isCanceled = axios.isCancel(e);
      } finally {
        if (!isCanceled) {
          this.isLoading = false;
        }
      }
    },
    handleDaySelect(day) {
      this.SET_DATE(day);
      this.SET_VIEW(CALENDAR_VIEW_TYPE_ENUM.WEEK);
    },
    handleChangeWeek(direction) {
      if (this.isMonthly) this.changeMonth(direction);
      else this.changeWeek(direction);
    },
    async handleNonWorkingDaysSave(days) {
      const confirm = await dialog.confirm({
        title: "Are you sure?",
        message: "Setting date as blackout may affect upcoming bookings",
        okText: "Confirm",
        cancelText: "Back",
      });

      if (confirm) {
        try {
          this.isNonWorkingDaysSubmitting = true;
          await this.setNonWorkingDays({
            productId: this.singleProduct.id,
            days,
          });
          await this.getCalendar();
          setTimeout(async () => {
            await dialog.alert({
              title: this.$t("Blackout dates have been successfully selected"),
              okText: "Done",
            });
          }, 0);
        } catch (e) {
          setTimeout(async () => {
            await dialog.alert({
              title: `An error occurred: ${e.message}`,
              okText: "Done",
            });
          }, 0);
        } finally {
          this.$modal.hide("calendarNonWorkingDaysModal");
          this.isNonWorkingDaysSubmitting = false;
        }
      }
    },
    handleCreate(slot) {
      const offset = getTimezoneOffsetHours(this.venue.timezone);
      const preSelectedSlot = {
        date: moment.utc(slot.dateIso).format("YYYY-MM-DD"),
        productId: slot.productId,
        start: getMinutes(slot.from) + offset * 60,
        end: getMinutes(slot.to) + offset * 60,
      };
      this.$router.push({
        name: "BookingCreate",
        params: { preSelectedSlot },
      });
    },
    handleOpen(slot) {
      this.$router.push({
        name: "Bookings",
        params: {
          selectedDate: moment.utc(slot.dateIso).format("YYYY-MM-DD"),
        },
      });
    },
    async handleEditAvailability(slot, isClone = false) {
      await this.fetchGroupById({
        productId: slot.productId,
        id: slot.groupId,
        isClone,
      });
      this.SET_IS_DRAWER_OPEN(true);
    },
    resetEditingGroup() {
      this.SET_EDITING_GROUP(null);
    },
    getTimeFromMinutes(time) {
      const hours = Math.floor(time / 60);
      const formattedHours = hours >= 10 ? hours : `0${hours}`;
      const minutes = time % 60;
      const formattedMinutes = minutes >= 10 ? minutes : `0${minutes}`;

      if (isNaN(minutes) || isNaN(hours)) return time;

      return `${formattedHours}:${formattedMinutes}`;
    },
    async handleCreateGroup(form) {
      try {
        this.isAvailableCreating = true;
        await this.createAvailability(form);
        await this.getCalendar();
        this.resetEditingGroup();
        await dialog.alert({
          title: "Slots were successfully created",
          okText: "Done",
        });
      } catch (e) {
        console.log("e > ", e);
        await dialog.alert({
          title: "An error occurred while creating slots",
          okText: "Ok",
        });
      } finally {
        this.isAvailableCreating = false;
      }
    },
    async handleEditGroup({ id, productId, ...data }) {
      try {
        this.isAvailableEditing = true;
        const { isBookingsAffected } = await CalendarService.updateSlotGroup({
          productId,
          groupId: id,
          data,
        });

        await this.getCalendar();
        this.resetEditingGroup();
        await dialog.alert({
          title: "Availability was successfully updated",
          message: isBookingsAffected
            ? 'Some bookings may be affected. If there are any, they will be marked as "Warning"'
            : "",
          okText: "Done",
        });
      } catch (ex) {
        console.log("ex > ", ex);
        await dialog.alert({
          title: "An error occurred while updating availability",
          okText: "Ok",
        });
      } finally {
        this.isAvailableEditing = false;
      }
    },
    async handleDeleteGroup({ id, productId }) {
      if (
        await dialog.confirm({
          title: "Are you sure?",
          message: "All availability data will be lost",
          okText: "Delete",
          cancelText: "Cancel",
        })
      ) {
        try {
          this.isAvailableDeleting = true;
          const { isBookingsAffected } = await CalendarService.deleteSlotGroup({
            productId,
            groupId: id,
          });
          await this.getCalendar();
          this.resetEditingGroup();
          await dialog.alert({
            title: "Availability was successfully deleted",
            message: isBookingsAffected
              ? 'Some bookings may be affected. If there are any, they will be marked as "Warning"'
              : "",
            okText: "Done",
          });
        } catch (ex) {
          await dialog.alert({
            title: "An error occurred while deleting availability",
            okText: "Ok",
          });
        } finally {
          this.isAvailableDeleting = false;
        }
      }
    },
    handleStartCreateAvailability() {
      eventBus.$emit("createAvailabilityModalOpened");
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-page {
  display: flex;
  gap: 4px;

  &__calendar {
    flex: 1;
  }

  @media (min-width: $media-laptop) {
    &__drawer {
      position: sticky;
      top: 0;
      margin-right: -44px;
      margin-top: -64px;
      margin-bottom: -48px;
      height: calc(100vh - 88px);
      z-index: 10;
    }
  }
}

.calendar-sticky {
  position: sticky;
  top: 0;
  z-index: 5;
  background: $background-color;
  padding: 16px 18px 0;
  margin: -16px -18px 0;
}

.calendar__fixed {
  position: fixed;
  bottom: 10px;
  right: 10px;
}
</style>
