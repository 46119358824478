<template>
  <div
    v-bind="$attrs"
    class="calendarCellSingle"
    :style="{
      top: `${position}px`,
      height: `${(duration * heightCoef) / 60}px`,
    }"
  >
    <div
      v-if="slotsSlice && slotsSlice.length > 1"
      class="calendarCellSingle__tabs"
    >
      <div
        class="calendarCellSingle__tabs__item"
        v-for="(slot, index) in slotsSlice"
        :key="index"
        :class="{ 'm-active': index + offset === primarySlotIndex }"
        :style="{ background: tabHighlightColor(slot) }"
        @click.stop="handleSlotSelect(index + offset)"
      >
        <div
          class="calendarCellSingle__tabs__item__color"
          :style="{ background: getGroupColor(slot) || 'black' }"
        />
      </div>
      <div
        class="calendarCellSingle__tabs__control"
        v-if="slots.length > limit"
      >
        <Icon
          isClickable
          name="arrow-left"
          :size="10"
          :color="offset <= 0 ? 'secondary-400' : 'secondary-500'"
          @click.stop="updateOffset(-1)"
        />
        <Icon
          isClickable
          name="arrow-right"
          :size="10"
          :color="
            offset >= this.slots.length - limit
              ? 'secondary-400'
              : 'secondary-500'
          "
          @click.stop="updateOffset(1)"
        />
      </div>
    </div>
    <div
      class="calendarCellSingle__wrapper"
      :class="{
        'm-withTabs': slots && slots.length > 1,
        'm-preview': isPreview,
        'm-private': isPrivate,
      }"
    >
      <div
        class="calendarCellSingle__highlight"
        :style="{
          background: highlightColor,
        }"
      />
      <div class="calendarCellSingle__timeRow">
        <div class="calendarCellSingle__time m-overflowed">
          <div class="calendarCellSingle__time__value">
            {{ time }}
          </div>
        </div>
        <div class="calendarCellSingle__duration">
          <span class="calendarCellSingle__durationText">{{ duration }}</span>
          <Icon name="clock" :size="10" color="secondary-400" />
        </div>
      </div>
      <div class="calendarCellSingle__priceRow">
        <div
          v-if="groupColor"
          class="calendarCellSingle__groupColor"
          :style="{ background: groupColor }"
        />
        <div
          v-if="fixedPrice || price"
          class="calendarCellSingle__price m-overflowed"
        >
          ${{ fixedPrice ? `${fixedPrice} fixed` : `${price} pp` }}
        </div>
      </div>
      <div class="calendarCellSingle__bookedRow">
        <span
          class="calendarCellSingle__booked m-overflowed"
          :class="{ 'm-overbooked': capacity && taken > capacity }"
        >
          {{
            capacity
              ? `${
                  taken > capacity ? "Overbooked" : "Booked"
                } ${taken}/${capacity}`
              : ""
          }}
        </span>
        <div v-if="productIcon" class="calendarCellSingle__productIcon">
          <Icon
            :name="productIcon"
            color="secondary-400"
            :size="12"
            v-tooltip="{
              content: productName,
              classes: 'calendarCellSingle__tooltip',
              boundariesElement: 'body',
            }"
          />
        </div>
      </div>
      <div
        v-if="capacity"
        class="calendarCellSingle__completeness"
        :style="{ width: `${this.completeness}%` }"
        :class="{ 'm-overbooked': taken > capacity }"
      />
    </div>
  </div>
</template>
<script>
import {
  getHourName,
  getMinutes,
  getSlotTimeFromMinutes,
  getTimezoneOffsetHours,
} from "@/helpers/utils";
import stringToColor from "string-to-color";
import { mapState } from "vuex";

export default {
  name: "CalendarCellSingle",
  props: {
    slots: {
      type: Array,
      default: () => [],
    },
    heightCoef: {
      type: Number,
      required: true,
    },
    primarySlotIndex: {
      type: Number,
      default: 0,
    },
    isPreview: {
      type: Boolean,
    },
  },
  data() {
    return {
      offset: 0,
      limit: 4,
    };
  },
  computed: {
    ...mapState({
      venue: (state) => state.venues.selectedVenue,
      highlightedGroupId: (state) => state.calendar.highlightedGroupId,
      editingGroup: (state) => state.calendar.editingGroup,
    }),
    primarySlot() {
      return this.slots[this.primarySlotIndex];
    },
    from() {
      return this.primarySlot?.from;
    },
    to() {
      return this.primarySlot?.to;
    },
    price() {
      return this.primarySlot?.price;
    },
    fixedPrice() {
      return this.primarySlot?.fixedPrice;
    },
    isPrivate() {
      return this.primarySlot?.isPrivate;
    },
    taken() {
      return this.primarySlot?.taken || 0;
    },
    capacity() {
      return this.primarySlot?.capacity;
    },
    groupId() {
      return this.primarySlot?.groupId;
    },
    groupLabel() {
      return this.primarySlot?.groupLabel;
    },
    duration() {
      return getMinutes(this.to) - getMinutes(this.from);
    },
    time() {
      return this.formatTime({ from: this.from, to: this.to });
    },
    completeness() {
      return Math.min(this.taken * (100 / this.capacity), 100);
    },
    status() {
      if (this.taken < this.capacity) return false;

      return this.taken > this.capacity ? "overbooked" : "full";
    },
    groupColor() {
      if (this.groupId) {
        return stringToColor(this.groupId);
      }
      return null;
    },
    position() {
      const utcOffset = getTimezoneOffsetHours(this.venue.timezone);
      const minutes = getMinutes(this.from) + utcOffset * 60;
      const coef = this.heightCoef / 60;

      return minutes * coef;
    },
    highlightColor() {
      const groupId = this.editingGroup?.id || this.highlightedGroupId;
      if (groupId && this.groupId && groupId === this.groupId) {
        const groupColor = this.getGroupColor(this.primarySlot);
        if (groupColor) {
          return `${groupColor}4D`;
        }
      }
      return null;
    },
    productIcon() {
      return this.primarySlot?.productIcon;
    },
    productName() {
      return this.primarySlot?.productName;
    },
    slotsSlice() {
      return this.slots.slice(this.offset, this.offset + this.limit);
    },
  },
  methods: {
    formatTime(slot) {
      const utcOffset = getTimezoneOffsetHours(this.venue.timezone);
      const fromWithOffset = getSlotTimeFromMinutes(
        getMinutes(slot.from) + utcOffset * 60
      );
      const toWithOffset = getSlotTimeFromMinutes(
        getMinutes(slot.to) + utcOffset * 60
      );
      return `${getHourName(
        fromWithOffset.hours,
        fromWithOffset.minutes
      ).toUpperCase()} - ${getHourName(
        toWithOffset.hours,
        toWithOffset.minutes
      ).toUpperCase()}`;
    },
    getGroupColor(slot) {
      return (slot?.groupId && stringToColor(slot.groupId)) || null;
    },
    handleSlotSelect(index) {
      this.$emit("update:primarySlotIndex", index);
    },
    updateOffset(inc) {
      this.offset = Math.max(
        0,
        Math.min(this.offset + inc, this.slots.length - this.limit)
      );
    },
    tabHighlightColor(slot) {
      const groupId = this.editingGroup?.id || this.highlightedGroupId;
      if (groupId && slot.groupId && slot.groupId === groupId) {
        const groupColor = this.getGroupColor(slot);
        if (groupColor) {
          return `${groupColor}4D`;
        }
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
.calendarCellSingle__tooltip {
  z-index: 3500;
}
</style>

<style lang="scss" scoped>
.calendarCellSingle {
  box-shadow: $box-shadow-small;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 2;
  cursor: pointer;
  transition: 0.1s;
  border-radius: 8px;

  &:hover {
    z-index: 3;
  }

  &__wrapper {
    position: relative;
    padding: 6px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: $secondary-500;
    border: 1px solid $secondary-300;
    border-radius: 8px;
    background-color: $white;
    overflow-x: hidden;

    &.m-preview {
      background: #cdf6c6;
      opacity: 0.8;
    }

    &.m-private {
      border-color: $danger;
    }

    &.m-withTabs {
      border-radius: 0 0 8px 8px;
      border-top: none;
    }
  }

  &__timeRow {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__priceRow {
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: 8px;
    font-weight: 500;
    line-height: 5px;
  }

  &__time__value {
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: 10px;
    font-weight: 500;
    line-height: 7px;
  }

  &__duration {
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: 8px;
    font-weight: 500;
    line-height: 5px;
    color: $secondary-400;
  }

  &__bookedRow {
    margin-top: auto;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__booked {
    color: $primary;
    font-size: 8px;
    line-height: 5px;

    &.m-overbooked {
      color: $danger;
    }
  }

  &__tooltip {
    z-index: 4;
    margin-bottom: 8px;
  }

  &__completeness {
    height: 3px;
    background-color: $primary;

    &.m-overbooked {
      background-color: $danger;
    }
  }

  &__groupColor {
    min-height: 6px;
    min-width: 6px;
    border-radius: 100%;
  }

  .m-overflowed {
    overflow-x: clip;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 4px;
  }

  &__slots {
    display: flex;
    flex-direction: column;
    gap: 2px;

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5px;
      font-weight: 500;
      font-size: 10px;
      line-height: 14px;
      color: $secondary-400;

      &__time {
        display: flex;
        align-items: center;
        gap: 5px;
      }

      &__price {
        font-size: 8px;
        color: rgba(44, 59, 85, 0.3);
      }
    }
  }

  &__highlight {
    position: absolute;
    inset: 0;
    transition: 0.1s;
    pointer-events: none;
  }

  &__productIcon {
    display: flex;
    align-items: flex-end;
    height: 5px;
    overflow: visible;
  }

  &__tabs {
    display: flex;
    align-items: flex-end;
    gap: 1px;
    height: 10px;

    &__control {
      display: flex;
      align-items: center;
      padding: 2px 2px 0;
      border-radius: 3px 3px 0 0;
      background: $white;
      border-width: 1px 1px 0 1px;
      border-style: solid;
      border-color: $secondary-300;
      max-height: 100%;
    }

    &__item {
      padding: 3px 7px;
      border-radius: 3px 3px 0 0;
      border-width: 1px 1px 0 1px;
      border-style: solid;
      border-color: $secondary-300;
      background: $secondary-300;
      transition: 0.1s;

      flex: 1;

      &.m-active {
        background: $white;
      }

      &__color {
        border-radius: 2px;
        height: 4px;
      }
    }
  }
}
</style>
