<template>
  <div class="calendar-product-selector">
    <p class="calendar-product-selector__label">Products:</p>
    <template v-if="selectedProducts?.length">
      <div
        v-for="product in selectedProducts"
        :key="product.id"
        class="calendar-product-selector__product"
      >
        <Icon v-if="product.icon" :name="product.icon" :size="12" />
        <p class="calendar-product-selector__product__name">
          {{ product.name }}
        </p>
        <Icon
          isClickable
          name="close"
          :size="12"
          @click="unselectProduct(product, true)"
        />
      </div>
    </template>
    <p v-else class="calendar-product-selector__label">None</p>
    <Popper
      ref="popper"
      trigger="clickToToggle"
      append-to-body
      :options="{
        placement: 'bottom-start',
        modifiers: { offset: { offset: '0,12' } },
      }"
      @hide="save"
    >
      <div class="calendar-product-selector__list">
        <div class="calendar-product-selector__list__item" @click="toggleAll">
          <span>All</span>
          <Icon
            v-if="innerValue.length === products.length"
            name="check-small"
            :size="16"
          />
        </div>
        <div
          v-for="product in products"
          :key="product.id"
          class="calendar-product-selector__list__item"
          :class="{ 'm-selected': innerValue.includes(product.id) }"
          @click="toggleProduct(product)"
        >
          <Icon v-if="product.icon" :name="product.icon" />
          <span>{{ product.name }}</span>
          <Icon
            v-if="innerValue.includes(product.id)"
            name="check-small"
            :size="16"
          />
        </div>
      </div>
      <Icon slot="reference" name="chevron-down" isClickable />
    </Popper>
  </div>
</template>

<script>
import Popper from "vue-popperjs";

export default {
  name: "CalendarProductSelector",
  components: { Popper },
  props: {
    value: {
      type: Array,
    },
    products: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      innerValue: [...this.value],
    };
  },
  computed: {
    selectedProducts() {
      return this.products.filter((product) =>
        this.innerValue.includes(product.id)
      );
    },
  },
  watch: {
    value() {
      this.innerValue = [...this.value];
    },
  },
  methods: {
    toggleProduct(product) {
      if (this.innerValue.includes(product.id)) {
        this.unselectProduct(product);
      } else {
        this.innerValue = [...this.innerValue, product.id];
      }
    },
    toggleAll() {
      if (this.innerValue.length === this.products.length) {
        this.innerValue = [];
      } else {
        this.innerValue = this.products.map((product) => product.id);
      }
      this.$refs.popper?.doClose();
    },
    unselectProduct(product, isSave = false) {
      this.innerValue = this.innerValue.filter((id) => id !== product.id);
      if (isSave) {
        this.save();
      }
    },
    save() {
      if (
        this.innerValue.length !== this.value.length ||
        this.innerValue.some((val) => !this.value.includes(val))
      ) {
        this.$emit("input", this.innerValue);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.calendar-product-selector {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;

  &__label {
    color: $secondary-500;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  &__product {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px 10px;
    background: $secondary-300;
    border-radius: 4px;

    &__name {
      color: $black;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      white-space: nowrap;
    }
  }

  &__list {
    padding: 4px 0;
    border-radius: 4px;
    background: $white;
    box-shadow: $box-shadow-small;
    z-index: 1000;
    width: 350px;

    &__item {
      display: flex;
      padding: 13px 32px;
      align-items: center;
      border-bottom: 1px solid var(--black-alpha-100, rgba(0, 0, 0, 0.06));
      gap: 8px;
      cursor: pointer;

      &:hover {
        background: $secondary-100;
        transition: 0.3s;
      }

      &.m-selected {
        background: $secondary-200;
      }

      & > span {
        flex: 1;
        color: $black;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
      }
    }
  }
}
</style>
